import React from 'react';
import {Column, Row} from "../utils/FlexBox";
import { getSkinnedCompanyName } from "../../containers/Home";
import HeatMap from "./HeatMap";
import InclusionBreakdown from "./InclusionBreakdown";
import InclusionTracker from "./InclusionTracker";
import Feedback from "./Feedback";
import TimeSeriesGate from "../utils/TimeSeriesGate";
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';
import { getActiveCompany } from 'utils';
import { useVersion } from 'hooks/useVersion';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { Redirect } from 'react-router-dom';
import './index.scss';

const Inclusion = (props) => {
    const { data: userMetadata } = useUserMetadata()
    const versionsContext = useVersion()

    const companyInformation = getActiveCompany(userMetadata?.companies)

    const companyName = getSkinnedCompanyName(
        companyInformation?.name,
        props?.profile
    );

    const isExportToExcelEnabled = companyInformation?.permissions?.export_to_excel;

    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2
    const { enableDebugMode } = props;

    if (isV2Enabled && !enableDebugMode) {
        return <Redirect to={generateCompanyURI("/analyze2/inclusion")} />
    }

    return (
        <div>
            <Row>
                <Column width="100%">
                    <HeatMap data={props.analyze.inclusionMetrics}
                        loadingNonOverviewMetrics={props.analyze.loadingNonOverviewMetrics}
                        prevData={props.analyze.previousInclusionMetrics}
                        overall={props.analyze.overall}
                        previousOverall={props.analyze.previousOverall}
                        companyName={companyName}
                        isExportToExcelEnabled={isExportToExcelEnabled}
                        getInclusionMetricsData={props.getInclusionMetricsData}
                        getInclusionMetricsExcel={props.getInclusionMetricsExcel}
                        getNonOverviewMetricsData={props.getNonOverviewMetricsData}
                        getNonOverviewMetricsExcel={props.getNonOverviewMetricsExcel}
                        allFilters={props.analyze.filters}
                        selected={props.selected}
                        selectTab={props.select}
                        metadata={props.analyze.metadata}
                        latestSurveyYear={props.analyze.filters.years?.[0]}
                    />
                </Column>
            </Row>
            <Row>
                <Column grow={1}>
                    <InclusionBreakdown 
                        data={props.analyze.inclusionBreakdown}
                        companyName={companyName}
                        groups={props.extendedCategories} filters={props.analyze.filters}
                        handleApplyFilters={data => props.getInclusionBreakdownData({ data })}
                        colors={props.colors}
                        showMessage={props.showMessage}
                        diversityComposition={props.analyze.diversityComposition}
                        latestSurveyYear={props.analyze.filters.years?.[0]}
                    />
                </Column>
            </Row>
            <TimeSeriesGate series={props.analyze.inclusionTracker}>
                <Row>
                <Column grow={1}>
                    <InclusionTracker
                        groups={props.extendedCategories}
                        metrics={props.analyze.inclusionTracker}
                        kpimap={props.kpimap}
                        filters={props.analyze.filters}
                        handleApplyFilters={data => props.getInclusionTrackerData({ data })}
                        allFilters={props.allFilters}
                        companyName={companyName}
                        latestSurveyYear={props.analyze.filters.years?.[0]}
                    />
                    </Column>
                </Row>
            </TimeSeriesGate>
            <Row>
                <Feedback
                    colors={props.colors}
                    freeText={props.analyze.freeText}
                    companyName={companyName}
                    diversityComposition={props.analyze.diversityComposition}
                    showMessage={props.showMessage}
                />
            </Row>
        </div>
    );
};

export default Inclusion;
