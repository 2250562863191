import React, { useMemo } from 'react'
import {
    getKpiLabel,
    HeatMapClassCell,
    HeatMapTableBodyCellSkeleton,
    HeatMapTableHeaderCell,
    IntersectionalityFilterRef,
    KpiKey,
} from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'
import { useIsFetching } from '@tanstack/react-query'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { RawColumn } from 'hooks/useInclusionData'
import { AnalyzeView } from 'types/analyze.enum'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetInclusionScoresSkeleton } from 'hooks/inclusion/useGetInclusionScoresSkeleton'
import { TABLE_VARIANTS } from '../enums'
import { MetricsData } from 'api/actions/analyze/inclusionData/inclusionDataActions.types'
import { Queries } from 'api/queries.enum'

import { AddProfileRow } from './AddProfileRow'
import { HeatmapTableRow } from './TableRow'
import { HeatmapTableProps, SkeletonColumn } from './table.types'
import * as S from './table.styles'

const HeatmapTableWithoutEB = ({
    label,
    onClick,
    data,
    table,
    selectedCellInfo: selectedTableCellInfo,
    showAddProfile,
    className,
    inclusionScoresSkeletonParams,
}: HeatmapTableProps) => {
    const cellInfo =
        selectedTableCellInfo?.table === table
            ? {
                  row: selectedTableCellInfo.row,
                  column: selectedTableCellInfo.column,
              }
            : undefined

    const { survey } = useSurveys()

    // Filter APIs only need year as a query param
    const filterParams = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, filterParams)
    const isQueryInclusionDataFetching = useIsFetching({ queryKey: [Queries.getInclusionData] }) > 0

    const queryGetInclusionScoresSkeleton = useGetInclusionScoresSkeleton(inclusionScoresSkeletonParams)

    const isInclusionScoresSkeletonVisible = isQueryInclusionDataFetching && !!queryGetInclusionScoresSkeleton.data

    const renderSkeletonHeaders = (
        columns: SkeletonColumn[],
        getKey: (column: SkeletonColumn) => string,
        getText: (column: SkeletonColumn) => string,
    ): JSX.Element[] => {
        return columns.map((column) => (
            <th key={`inclusion-header-cell-${getKey(column)}`}>
                <HeatMapTableHeaderCell text={getText(column)} />
            </th>
        ))
    }

    const renderSkeletonRows = (
        data: MetricsData,
        columns: {
            key: string
            title?: string
        }[],
    ) => {
        return data.map((_, rowIndex) => (
            <S.TableRow key={rowIndex}>
                {columns.map((_, colIndex) => (
                    <td key={colIndex}>
                        <HeatMapTableBodyCellSkeleton key={colIndex} />
                    </td>
                ))}
            </S.TableRow>
        ))
    }

    return (
        <S.Table className={className}>
            <thead>
                <tr>
                    <th colSpan={100}>
                        <HeatMapClassCell name={label} />
                    </th>
                </tr>

                <S.TableHeader>
                    {table === TABLE_VARIANTS.INCLUSION &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonHeaders(
                            queryGetInclusionScoresSkeleton.data?.inclusionMetrics.columns,
                            (column) => column.key || '',
                            (column) => getKpiLabel(column.key as KpiKey) || '',
                        )}

                    {table === TABLE_VARIANTS.BESPOKE &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonHeaders(
                            queryGetInclusionScoresSkeleton.data?.bespokeMetrics.columns,
                            (column) => column.title || '',
                            (column) => column.title || '',
                        )}

                    {table === TABLE_VARIANTS.ENGAGEMENT &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonHeaders(
                            queryGetInclusionScoresSkeleton.data?.engagementMetrics.columns,
                            (column) => column.title || '',
                            (column) => column.title || '',
                        )}

                    {!isQueryInclusionDataFetching &&
                        data.columns.length > 0 &&
                        data.columns.map((dataColumn: RawColumn, idx: number) => {
                            return (
                                <th key={`inclusion-header-cell-${idx}`}>
                                    <HeatMapTableHeaderCell
                                        text={dataColumn.title}
                                        textTooltipTitle={dataColumn.questionTitle}
                                        textTooltipProps={{
                                            variant: 'light',
                                            size: 'medium',
                                            placement: 'top',
                                        }}
                                    />
                                </th>
                            )
                        })}
                </S.TableHeader>
            </thead>

            <tbody>
                <>
                    {table === TABLE_VARIANTS.INCLUSION &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonRows(
                            queryGetInclusionScoresSkeleton.data?.inclusionMetrics.data || [],
                            queryGetInclusionScoresSkeleton.data?.inclusionMetrics.columns || [],
                        )}

                    {table === TABLE_VARIANTS.BESPOKE &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonRows(
                            queryGetInclusionScoresSkeleton.data?.bespokeMetrics.data || [],
                            queryGetInclusionScoresSkeleton.data?.bespokeMetrics.columns || [],
                        )}

                    {table === TABLE_VARIANTS.ENGAGEMENT &&
                        isInclusionScoresSkeletonVisible &&
                        renderSkeletonRows(
                            queryGetInclusionScoresSkeleton.data?.engagementMetrics.data || [],
                            queryGetInclusionScoresSkeleton.data?.engagementMetrics.columns || [],
                        )}
                </>

                {!isQueryInclusionDataFetching &&
                    data.data.length > 0 &&
                    data.data.map((inclusionData, idx) => {
                        const { scores } = inclusionData

                        return (
                            <HeatmapTableRow
                                key={`heatmap-table-row-${idx}`}
                                onClick={(row, column) => onClick(table, row, column)}
                                rowId={idx}
                                data={scores}
                                selectedCellInfo={cellInfo}
                            />
                        )
                    })}

                {showAddProfile && (queryGetCompanyProfiles.data?.profiles.length || 0) < 4 ? (
                    <AddProfileRow columns={data.columns} />
                ) : null}
            </tbody>
        </S.Table>
    )
}

export const HeatmapTable = withErrorBoundary<HeatmapTableProps & { ref: React.Ref<IntersectionalityFilterRef> }>(
    HeatmapTableWithoutEB,
    {},
)
