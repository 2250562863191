import React, { ChangeEvent, useState } from 'react'
import { FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core'
import { Checkbox, getKpiLabel, KpiKey } from '@diversioteam/diversio-ds'

import { SidePanel } from './SidePanel'
import { Training } from './Training'
import { parseData } from './trainings.helpers'
import { DataItemOutput, Level } from './trainings.types'
import trainingsData from './trainings.data.json'
import * as S from './trainings.styles'

const AVAILABLE_LEVELS: Level[] = ['Fundamentals', 'Intermediate', 'Advanced']
const AVAILABLE_KPIS: KpiKey[] = [
    'INCLUSIVE_CULTURE',
    'CAREER_DEVELOPMENT',
    'FAIR_MANAGEMENT',
    'WORKPLACE_FLEXIBILITY',
    'WORKPLACE_SAFETY',
    'RECRUITING_HIRING',
]

export const Trainings = () => {
    const data: DataItemOutput[] = parseData(trainingsData)

    const [selectedTrainingId, setSelectedTrainingId] = useState<number | null>(null)
    const selectedTraining = data.find(({ id }) => id === selectedTrainingId)

    const targetGroups = Array.from(
        new Set(data.flatMap(({ targetGroups }) => targetGroups.flatMap(({ key }) => key))),
    ).filter((key) => key !== 'Everyone')

    const [selectedLevel, setSelectedLevel] = useState<string>('ALL')
    const [selectedKpis, setSelectedKpis] = useState<string[]>([])
    const [selectedTargetGroups, setSelectedTargetGroups] = useState<string[]>([])

    const hasKpisSelected = selectedKpis.length > 0
    const hasTargetGroupsSelected = selectedTargetGroups.length > 0

    const handleChangeLevel = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedLevel(event.target.value)
    }

    const handleChangeKpi = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'ALL') {
            return setSelectedKpis([])
        }

        if (event.target.checked) {
            return setSelectedKpis([...selectedKpis, event.target.name])
        }

        return setSelectedKpis(selectedKpis.filter((selectedKpi) => selectedKpi !== event.target.name))
    }

    const handleChangeTargetGroup = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'Everyone') {
            return setSelectedTargetGroups([])
        }

        if (event.target.checked) {
            return setSelectedTargetGroups([...selectedTargetGroups, event.target.name])
        }

        return setSelectedTargetGroups(
            selectedTargetGroups.filter((selectedTargetGroup) => selectedTargetGroup !== event.target.name),
        )
    }

    const handleCloseSidePanel = () => {
        setSelectedTrainingId(null)
    }

    const filteredData = data
        .filter(({ level }) => {
            if (selectedLevel !== 'ALL') {
                return level === selectedLevel
            }

            return true
        })
        .filter(({ kpis }) => {
            if (!hasKpisSelected) {
                return true
            }

            if (kpis.find(({ key }) => key === 'ALL')) {
                return true
            }

            if (selectedKpis.some((selectedKpi) => kpis.find(({ key }) => key === selectedKpi))) {
                return true
            }

            return false
        })
        .filter(({ targetGroups }) => {
            if (!hasTargetGroupsSelected) {
                return true
            }

            if (targetGroups.find(({ key }) => key === 'Everyone')) {
                return true
            }

            if (
                selectedTargetGroups.some((selectedTargetGroup) =>
                    targetGroups.find(({ key }) => key === selectedTargetGroup),
                )
            ) {
                return true
            }

            return false
        })

    return (
        <S.TrainingsWrapper>
            <S.FiltersWrapper>
                <S.Filters>
                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>Training Level</S.FiltersGroupTitle>

                        <RadioGroup aria-label="quiz" name="quiz" value={selectedLevel} onChange={handleChangeLevel}>
                            <FormControlLabel value="ALL" control={<Radio color="default" />} label="All" />

                            {AVAILABLE_LEVELS.map((level) => (
                                <FormControlLabel
                                    key={level}
                                    value={level}
                                    control={<Radio color="default" />}
                                    label={level}
                                />
                            ))}
                        </RadioGroup>
                    </S.FiltersGroup>

                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>Inclusion Metric</S.FiltersGroupTitle>

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={!hasKpisSelected} onChange={handleChangeKpi} name="ALL" />}
                                label="Select All"
                            />

                            {AVAILABLE_KPIS.map((kpi) => (
                                <FormControlLabel
                                    key={kpi}
                                    control={
                                        <Checkbox
                                            checked={selectedKpis.includes(kpi)}
                                            onChange={handleChangeKpi}
                                            name={kpi}
                                        />
                                    }
                                    label={getKpiLabel(kpi)}
                                />
                            ))}
                        </FormGroup>
                    </S.FiltersGroup>

                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>Target Groups</S.FiltersGroupTitle>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!hasTargetGroupsSelected}
                                        onChange={handleChangeTargetGroup}
                                        name="Everyone"
                                    />
                                }
                                label="Everyone"
                            />

                            {targetGroups.map((key) => (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            checked={selectedTargetGroups.includes(key)}
                                            onChange={handleChangeTargetGroup}
                                            name={key}
                                        />
                                    }
                                    label={key}
                                />
                            ))}
                        </FormGroup>
                    </S.FiltersGroup>
                </S.Filters>
            </S.FiltersWrapper>

            <S.Content>
                <S.Title>Trainings</S.Title>

                <S.List>
                    {filteredData.map((props) => (
                        <S.ListItem key={props.id} onClick={() => setSelectedTrainingId(props.id)}>
                            <Training isSelected={selectedTrainingId === props.id} {...props} />
                        </S.ListItem>
                    ))}
                </S.List>
            </S.Content>

            {selectedTraining && (
                <S.SidePanelWrapper data-testid="side-panel">
                    <SidePanel onClose={handleCloseSidePanel} {...selectedTraining} />
                </S.SidePanelWrapper>
            )}
        </S.TrainingsWrapper>
    )
}
