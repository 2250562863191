import React from 'react'
import { useTranslation } from 'react-i18next'

import { FooterProps } from './footer.types'
import * as S from './footer.styles'

export const Footer = (props: FooterProps) => {
    const { t } = useTranslation()

    const year = new Date().getFullYear()

    return (
        <S.Footer {...props}>
            <S.Link href="https://diversio.com/" target="_blank">
                &copy; {year} Diversio
            </S.Link>

            <S.LinksDivider>|</S.LinksDivider>

            <S.Link href="https://diversio.com/privacy-policy" target="_blank">
                {t('AUTH.LOGIN.PRIVACY_POLICY')}
            </S.Link>
        </S.Footer>
    )
}
