import React, { ReactNode } from 'react'

import * as S from 'components/Auth/auth.styles'

export const AuthBillboard = ({ children }: { children: ReactNode }) => {
    return (
        <S.Container hasBillboard>
            <S.Billboard>
                <iframe src="https://billboard.framer.website/" title="Diversio Billboard" />
            </S.Billboard>

            {children}
        </S.Container>
    )
}
