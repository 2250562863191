export enum Queries {
    getCompanyFilters = 'getCompanyFilters',
    getDiversityCompanyProfiles = 'getDiversityCompanyProfiles',
    getDiversityCompanyProfilesOptions = 'getDiversityCompanyProfilesOptions',
    getInclusionCompanyProfiles = 'getInclusionCompanyProfiles',
    getInclusionCompanyProfilesOptions = 'getInclusionCompanyProfilesOptions',
    getFreeTextsFilters = 'getFreeTextsFilters',
    getFreeTexts = 'getFreeTexts',
    getBespokeFreeTexts = 'getBespokeFreeTexts',
    getBlogs = 'getBlogs',
    getPrograms = 'getPrograms',
    getTools = 'getTools',
    getAllTools = 'getAllTools',
    getPeopleSurveyedByRole = 'getPeopleSurveyedByRole',
    getEventReminders = 'getEventReminders',
    getDiversityBreakdownByRole = 'getDiversityBreakdownByRole',
    getInclusionInsights = 'getInclusionInsights',
    getInclusionHeatmap = 'getInclusionHeatmap',
    getDiversityBreakdown = 'getDiversityBreakdown',
    getReports = 'getReports',
    getMyReports = 'getMyReports',
    getProgramFilters = 'getProgramFilters',
    getToolsFilters = 'getToolsFilters',
    getUserMetadata = 'getUserMetadata',
    getPastSurveys = 'getPastSurveys',
    getAnnouncements = 'getAnnouncements',
    getInspirationalQuotes = 'getInspirationalQuotes',
    getProgressBanner = 'getProgressBanner',
    getRiskBanner = 'getRiskBanner',
    getDiversityData = 'getDiversityData',
    getDiversityDataOverTime = 'getDiversityDataOverTime',
    getInclusionData = 'getInclusionData',
    getInclusionDataOverTime = 'getInclusionDataOverTime',
    getInclusionPanelData = 'getInclusionPanelData',
    getNps = 'getNps',
    getConfiguration = 'getConfiguration',
}
