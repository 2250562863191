import styled from 'styled-components'

import { RegionSwitch as DefaultRegionSwitch } from './../RegionSwitch'

export const RegionSwitch = styled(DefaultRegionSwitch)`
    position: absolute;
    bottom: 40px;
    left: 40px;
`

export {
    Billboard,
    Button,
    Container,
    Form,
    FormWrapper,
    Logo,
    Title,
    Fields,
    TextField,
    TextLink,
    Toast,
} from './../auth.styles'
