import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ReloadIcon, SearchBox, Toast } from '@diversioteam/diversio-ds'
import { Actions } from 'actions'
import _ from 'underscore'
import { ToolCardListSkeleton } from 'components/skeletons/toolCardListSkeleton'

import { useDebounce } from 'hooks/useDebounce'
import { RootState } from 'reducers/rootState.types'
import { Tools as ToolsList } from 'components/Tools'
import { useGetTools } from 'hooks/tools/useGetTools'
import { ProgramFilterProps } from 'types/programs/programFilters.types'
import { ParsedFilterOption } from 'types/programs/common.types'
import diversioToolImg from 'images/diversioTool.png'

import { ToolsFilters } from './ToolsFilters/ToolsFilters'
import * as S from './tools.styles'

const PAGE_SIZE = 9

export const Tools = () => {
    const [searchValue, setSearchValue] = useState('')
    const [filters, setFilters] = useState<ProgramFilterProps['values']>({})

    const debouncedSearchValue = useDebounce<string>(searchValue, 500)

    const queryGetTools = useGetTools({
        page_size: PAGE_SIZE,
        search: debouncedSearchValue,
        ...filters,
    })

    const { toastError } = useSelector((state: RootState) => {
        return state.message
    })

    const dispatch = useDispatch()

    const tools = queryGetTools.data?.pages
    const hasNextPage = tools && tools[tools.length - 1].next !== null

    const handleChangeSearch = (value: string) => {
        setSearchValue(value)
    }

    const handleFilterChange = (name: string, selectedFilters: ParsedFilterOption | ParsedFilterOption[] | null) => {
        /**
         * Handle a user changing filters from kpi or target groups dropdown
         */
        if (!selectedFilters) {
            return
        }

        const newFilters = {
            ...filters,
            [name]: _.pluck(selectedFilters, 'value').join(','),
        }

        setFilters(newFilters)
    }

    const handleClickLoadMore = () => {
        queryGetTools.fetchNextPage()
    }

    return (
        <S.Tools>
            {toastError?.type && (
                <div className="ds-toast-component">
                    <Toast
                        open={true}
                        type={toastError?.type}
                        message={toastError?.message}
                        onClick={() => dispatch(Actions.resetGlobalToast())}
                    />
                </div>
            )}

            <S.Card variant="dark">
                <div>
                    <S.CardTitle>Diversio Tools</S.CardTitle>

                    <S.CardDescription>
                        Training modules, suggested policies, & other devices to better equip you to drive change
                    </S.CardDescription>
                </div>

                <img src={diversioToolImg} />
            </S.Card>

            <S.Filters>
                <SearchBox maxWidth={198} value={searchValue} onChange={handleChangeSearch} />
                <ToolsFilters values={filters} handleFilterChange={handleFilterChange} />
            </S.Filters>

            <S.ToolsList>
                {queryGetTools.isInitialLoading ? (
                    <ToolCardListSkeleton
                        size="medium"
                        numberOfElements={PAGE_SIZE}
                        listElementWrapperClassName="tool-card-wrapper tool-page-margin"
                    />
                ) : (
                    tools?.flatMap(({ results }) =>
                        results.map((props) => <ToolsList {...props} size="medium" key={props.id} />),
                    )
                )}
            </S.ToolsList>

            {hasNextPage && (
                <S.LoadMore>
                    <Button
                        rightIcon={<ReloadIcon type="line" />}
                        type="button"
                        color="secondary"
                        onClick={handleClickLoadMore}
                        loading={queryGetTools.isFetchingNextPage}
                    >
                        Load More
                    </Button>
                </S.LoadMore>
            )}
        </S.Tools>
    )
}
