import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetConfigurationResponse } from 'api/actions/configuration/configurationActions.types'
import { getConfiguration } from 'api/actions/configuration/configurationActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

import { useQuery } from './useQuery'

export const useGetConfiguration = () => {
    const query = useQuery<GetConfigurationResponse, AxiosError>({
        queryKey: [Queries.getConfiguration],
        queryFn: getConfiguration,
        ...preventRefetchQueryOptions,
    })

    return query
}
