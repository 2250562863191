import { generatePath } from 'react-router-dom'

export const endpoints = {
    benchmarks: {
        national: {
            list: 'company/companyUUID/benchmark-census/',
            setPrimary: (id: string) => generatePath('company/companyUUID/benchmark-census/:id/set-primary/', { id }),
        },
        industry: {
            list: 'company/companyUUID/benchmark-industry/',
            setPrimary: (id: string) => generatePath('company/companyUUID/benchmark-industry/:id/set-primary/', { id }),
        },
    },
    nps: 'home/company/companyUUID/past-nps/',
}
