import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import { 
    InclusionScore,
    InclusionScoreCustom,
    EmployeesSurveyed,
    MetricOverview,
    HeatMap,
    FocusAreas,
    Certification,
    ExecutiveSummary,
    DiversityComposition
} from '../components/Home/';
import { Row, Column } from '../components/utils/FlexBox';
import { isCifarGlobalCall, isCifarDLRL, isCifarScholars, isCifarGlobalCallOverall, isBessemerBriefPortfolio, isBessemerAnonymized, isCaiPortfolio, isBMCWinterSchool, isCifarSolutionsNetwork } from '../utils/companyChecks'
import mixpanel from "mixpanel-browser";
import { beaconIdentify } from "../utils/beacon";
import Joyride from 'react-joyride';
import TAXONOMIES from "../utils/taxonomies";
import { Redirect } from 'react-router-dom';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { VersionsContext } from 'context/versions/versionsContext/VersionsContext';
import { withHooks } from 'config/withHooks/withHooks';
import { getActiveCompany } from 'utils';
import './App.scss';

/* Data for BusinessKeyPerformanceIndicator. Delete once backend has this */
const BKPIdata = [
    {
        "description": "Employee Attrition Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "40"
            },
            {
                "month": "April 2019",
                "rate": "30"
            },
            {
                "month": "May 2019",
                "rate": "20",
                "difference": "10"
            },
        ]
    },
    {
        "description": "Offer Acceptance Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "75"
            },
            {
                "month": "April 2019",
                "rate": "80"
            },
            {
                "month": "May 2019",
                "rate": "88",
                "difference": "8"
            },
        ]
    },
    {
        "cost": 0,
        "description": "Spent on discrimination litigation"
    }
]

// temp for demo - find better solution to this if it becomes a trend
export const getSkinnedCompanyName = (originalCompanyName, profile) => {
    /* Use the username info in profile payload to do some conditional skinning for demos. */

    if (!originalCompanyName) {
        return '';
    }
    
    if (originalCompanyName && !profile) {
        return originalCompanyName;
    }
    
    const usernameCompanynameMap = {
        "demo_amazing_procurer": "Amazing Procurer"
    };

    if (profile.username && usernameCompanynameMap[profile.username]) {
        return usernameCompanynameMap[profile.username];
    }

    return originalCompanyName;

}

class Home extends Component {
    static contextType = VersionsContext

    componentWillMount () {
        this.props.getEmployeeOverviewData();
        this.props.getInclusionMetricsData({ data: { year: "", level: "", office: "", department: "" } }); // Used in Home only for special case 'isCaiPortfolio'
        this.props.getDiversityCompositionData();
        this.props.getMetricOverviewData();
        this.props.getFocusAreaData();
        this.props.getInclusionScoreData();
        this.props.getExecSummaryData();
    }

    state = {
        steps: [
            {
                target: '.content-container',
                content: 'Welcome to the dashboard. Here, you will be able to analyze the results of your survey.',
                disableBeacon: true,
            },
            {
                target: '.icon-question-mark',
                content: 'Click here to learn more about this component',
            },
        ],
        showJoyride: false
    };

    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Home"
        })

        const companies = this.props.userMetadata?.companies
        const allCompaniesString = companies.map(company => company.name).join(', ')
        const selectedCompanyInformation = getActiveCompany(companies)

        beaconIdentify(this.props.username, this.props.email, allCompaniesString, selectedCompanyInformation?.name)
        if (this.props.location.state?.from === "diversio-basic-dashboard-create") {
            this.setState({ showJoyride: true });
            mixpanel.track(TAXONOMIES.DASHBOARD_CREATED)
        }
    }
    
    render() {
        const isV2Enabled = this.context?.version === this.context?.AVAILABLE_VERSIONS.v2
        const { enableDebugMode } = this.props;
        const companyInformation = getActiveCompany(this.props.userMetadata?.companies)

        if (isV2Enabled && !enableDebugMode) {
            return <Redirect to={generateCompanyURI("/home2")} />
        }

        const { steps, showJoyride } = this.state;
       
        const companyName = getSkinnedCompanyName(companyInformation?.name, this.props.profile)
        const isCustom = (isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isCifarDLRL(companyName) || isCifarGlobalCallOverall(companyName) || isBessemerBriefPortfolio(companyName) || isBessemerAnonymized(companyName) || isBMCWinterSchool(companyName) || isCifarSolutionsNetwork(companyName))
        return (
            <div className="App">
                {
                    showJoyride ? <Joyride steps={steps}
                                           continuous={true}
                                           locale={{ last: 'Close' }}
                                           styles={{
                                               options:{
                                                   primaryColor: '#6824ec'
                                               }
                                           }}
                                           scrollOffset={250}
                                           callback={data => {
                                               const { status } = data;
                                               if (status === "finished") {
                                                   localStorage.setItem("joyride__create--completed", "1");
                                               }
                                           }}
                    /> : null
                }

                <Row>
                    <Column width="30%">
                        {isCustom ? 
                            <InclusionScoreCustom
                                companyName={companyInformation?.name} 
                            /> : 
                            <InclusionScore data={this.props.inclusionScore} companyName={companyName} />
                        }
                    </Column>
                    <Column width="40%">
                        <EmployeesSurveyed data={this.props.employeeOverview} companyName={companyInformation?.name} colors={this.props.colors}/>
                    </Column>
                    <Column width="30%">
                        <Certification inclusionScore={this.props.inclusionScore} companyName={companyInformation?.name}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <ExecutiveSummary data={this.props.execSummary}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <DiversityComposition
                        breakdown={this.props.diversityCompositionBreakdown}
                        data={this.props.diversityComposition}
                        ndg={this.props.analyze.filters.non_dominant_group}
                        colors={this.props.colors}
                        industryData={this.props.industryDiversityComposition}
                        companyName={companyName}
                        profile={this.props.profile}
                        />
                    </Column>
                </Row>
                {isCustom ? null : (
                    <>
                    {!isCaiPortfolio(companyName) ? <Row>
                        <Column grow={1}>
                            <HeatMap data={this.props.analyze.inclusionMetrics} 
                            prevData={this.props.analyze.previousInclusionMetrics}
                            metadata={this.props.analyze.metadata}
                            companyName={companyName}
                            years={this.props.analyze.filters.years}
                            />
                        </Column>
                    </Row> : null}
                   <Row>
                        <Column width="100%">
                            <FocusAreas data={this.props.focusArea} 
                            companyName={companyName}/>
                        </Column>
                    </Row>
                    </>
                ) }
                {(this.props.metricOverview.length > 1) ? <Row>
                    <Column width="100%">
                        <MetricOverview data={this.props.metricOverview} BKPIdata={BKPIdata}
                        companyName={companyName} />
                    </Column>
                </Row> : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.home,
        ...state.auth,
        analyze: state.analyze,
        colors: state.colors,
        profile: state.profile,
    };
};

export default connect(mapStateToProps, Actions)(withHooks(Home));
