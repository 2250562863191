import { useMutation, useQueryClient } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'

import { Mutations } from 'api/actions/home/mutations.enum'
import { Queries } from 'api/actions/home/queries.enum'
import { GetBenchmarksResponse } from 'api/actions/home/benchmarks/benchmarksActions.types'
import { setPrimaryIndustryBenchmark, setPrimaryNationalBenchmark } from 'api/actions/home/benchmarks/benchmarkActions'
import TAXONOMIES from 'utils/taxonomies'

export const useSetPrimaryBenchmark = (type: 'national' | 'industry') => {
    const queryClient = useQueryClient()

    const mutationKey =
        type === 'national' ? [Mutations.setPrimaryNationalBenchmark] : [Mutations.setPrimaryIndustryBenchmark]
    const mutationFn = type === 'national' ? setPrimaryNationalBenchmark : setPrimaryIndustryBenchmark

    const queryKey = type === 'national' ? [Queries.getNationalBenchmarks] : [Queries.getIndustryBenchmarks]

    const mutation = useMutation<void, Error, string, GetBenchmarksResponse>({
        mutationKey,
        mutationFn,
        onMutate: async (newPrimaryBenchmark) => {
            await queryClient.cancelQueries({ queryKey })

            const previousBenchmarks = queryClient.getQueryData<GetBenchmarksResponse>(queryKey)

            mixpanel.track(
                type === 'industry' ? TAXONOMIES.SWITCH_INDUSTRY_BENCHMARK : TAXONOMIES.SWITCH_NATIONAL_BENCHMARK,
                {
                    nextBenchmark: previousBenchmarks?.find(({ uuid }) => newPrimaryBenchmark === uuid)?.label,
                    previousBenchmark: previousBenchmarks?.find(({ isPrimary }) => isPrimary)?.label,
                    availableBenchmarks: previousBenchmarks?.map(({ label }) => label),
                },
            )

            queryClient.setQueryData<GetBenchmarksResponse>(queryKey, (oldBenchmarks) => {
                if (!oldBenchmarks) {
                    return
                }

                const newBenchmarks = oldBenchmarks.map((item) => {
                    if (item.uuid === newPrimaryBenchmark) {
                        return Object.assign({}, item, { isPrimary: true })
                    } else {
                        return Object.assign({}, item, { isPrimary: false })
                    }
                })

                return newBenchmarks
            })

            if (previousBenchmarks) {
                return [...previousBenchmarks]
            }
        },
        onError: (err, newPrimaryBenchmark, context) => {
            queryClient.setQueryData(queryKey, context)
        },
        onSuccess: () => {
            queryClient.invalidateQueries([Queries.getPeopleSurveyedByRole])
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    return mutation
}
