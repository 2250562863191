import * as m from '../translations/mapping';
import { mapT } from '../translations/utils';
import {
    isMcrockDueDiligence,
    isPortfolioCompany,
    isOrganizationCompany,
    officeIsCountry,
    nameMatches,
    isCia,
    isHondaHTC,
    isHRPA,
    isBessemerBriefPortfolio,
    isCPAOntario,
    isCPAMembersOntario,
    isCifarScholars,
    isCifarGlobalCall,
    isCifarDLRL,
    isCifarCore,
    isCifarPanCanadianAIStrategy,
    isCifarGlobalCallOverall,
    isCifarSolutionsNetwork,
    isCifarWithStages,
    isBrucePower,
    isKingSettCapital,
    isSickKidsFoundation,
    isBlueOwl,
    isCenterbridgeLeadership,
    isCifarGov,
    isOntarioTeachersPensionPlanBoardOTPP,
    isBigHand,
    isBCI,
    isBMCWinterSchool,
    isIllinoisShines,
    isEnergySolutions,
    isMila,
    isBCIApplicants,
    isBCIStudents,
} from "./companyChecks";

// WARNING this is not an efficient solution, should not be used for large strings
export function replaceAll(str, _find, replace) {
    const find = Array.isArray(_find) ? _find : [_find];

    let alteredStr = str;
    for (const f of find) {
        alteredStr = alteredStr.split(f).join(replace)
    }

    return alteredStr;
}

const CIA_META_KPI_MAP = {
    "Fair Management": "Unbiased Community",
    "Workplace Flexibility": "Flexibility",
    "Workplace Safety": "Safe Environment",
    "Recruiting & Hiring": "Member onboarding",
}

export const mapMetaKpiLabels = (companyName, metaName) => {
    if (isCia(companyName)) {
        return (CIA_META_KPI_MAP[metaName] || metaName);
    } else {
        return metaName;
    }
}

// NOTE that order matters on this ladder!
export const getOfficeRegionLabel = (companyName) => {
    if (isBessemerBriefPortfolio(companyName)) {
        return mapT(m.FILTER_LABELS, "location");
    }
    if (isPortfolioCompany(companyName)) {
        return mapT(m.FILTER_LABELS, "company");
    } else if (officeIsCountry(companyName)) {
        return mapT(m.FILTER_LABELS, "country");
    } else if (nameMatches(companyName, "accenture")) {
        return mapT(m.FILTER_LABELS, "practice");
    } else if (isCia(companyName)) {
        return mapT(m.FILTER_LABELS, "status");
    } else if (isHRPA(companyName)) {
        return mapT(m.FILTER_LABELS, "industry");
    } else if (isOrganizationCompany(companyName)) {
        return mapT(m.FILTER_LABELS, "organization");
    } else if (isCPAOntario(companyName)) {
        return mapT(m.FILTER_LABELS, "team");
    } else if (isCPAMembersOntario(companyName)) {
        return mapT(m.FILTER_LABELS, "agebucket");
    } else if(isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isCifarDLRL(companyName) || isCifarCore(companyName) || isCifarPanCanadianAIStrategy(companyName) || isCifarGlobalCallOverall(companyName) || isBMCWinterSchool(companyName) || isCifarSolutionsNetwork(companyName)) {
        return mapT(m.FILTER_LABELS, "country")
    } else if (isBrucePower(companyName)) {
        return(mapT(m.FILTER_LABELS, "sub department"))
    } else if(isKingSettCapital(companyName)) {
        return(mapT(m.FILTER_LABELS, "age"))
    } else if(isSickKidsFoundation(companyName)) {
        return(mapT(m.FILTER_LABELS, "age"))
    } else if(isBlueOwl(companyName)) {
        return(mapT(m.FILTER_LABELS, "age"))
    } 
    else if(isBigHand(companyName)) {
        return(mapT(m.FILTER_LABELS, "businessArea"))
    }
    else if (isBCIApplicants(companyName)) {
        return(mapT(m.FILTER_LABELS, "completedStatus"))
    }
    else if(isBCI(companyName)) {
        return(mapT(m.FILTER_LABELS, "employeeStatus"))
    }
    else if (isMila(companyName)) {
        return mapT(m.FILTER_LABELS, "Graduated 04/22 - 31/03/23");
    }


    return mapT(m.FILTER_LABELS, "Office / Region");
}

export const getTenureLabel = (companyName) => {
    if (isCPAMembersOntario(companyName)) {
        return "Organization Size"
    }

    if (isCifarGlobalCallOverall(companyName)) {
        return "Stage"
    }

    else if (isBCIApplicants(companyName)) {
        return(mapT(m.FILTER_LABELS, "jobClass"))
    }

    if (isOntarioTeachersPensionPlanBoardOTPP(companyName) || isBMCWinterSchool(companyName)) {
        return "Age"
    }

    return mapT(m.FILTER_LABELS, "tenure");
}

export const getYearLabel = (companyName) => {
    if (isCifarWithStages(companyName)) {
        return "Stage"
    }

    return mapT(m.FILTER_LABELS, "year");
}

export const BCI_YEAR_MAPPING = {
    "2023Q1": "2022 Q4: January - March",
    "2023Q2": "2023 Q1: April - June",
    "2023Q3": "2023 Q2: July - September",
    "2023Q4": "2023 Q3: October - December",
}

export const BCI_APPLICANTS_YEAR_MAPPING = {
    "2023Q2": "2023 Q1: April - June",
    "2023Q3": "2023 Q2: July - September",
    "2023Q4": "2023 Q3: October - December",
}

export const CIFAR_STAGE_MAPPING = {
    "CIFAR DLRL 2022": {
        "2022Q3": "Applied",
        "2022Q2": "Awarded",
        "2022Q1": "Attended"
    },
    "CIFAR DLRL 2023": {
        "2023Q3": "Applied",
        "2023Q2": "Awarded",
        "2023Q1": "Attended"
    },
    "CIFAR DLRL 2024": {
        "2024Q4": "Applied",
        "2024Q3": "Awarded",
        "2024Q2": "Attended",
    },
    "CIFAR Global Call 2022": {
        "2023Q1": "LOI",
        "2022Q4": "Awarded",
        "2022Q3": "Full Proposal",
        "2021Q4": "Registration",
    },
    "CIFAR Global Scholars 2021": {
        "2022Q2": "Applied",
        "2022Q1": "Shortlisted",
        "2021Q4": "Awarded"
    },
    "CIFAR Global Scholars 2022": {
        "2023Q1": "Applied",
        "2022Q4": "Shortlisted",
        "2022Q3": "Awarded"
    },
    "CIFAR Global Scholars 2023": {
        "2023Q4": "Applied",
        "2023Q3": "Shortlisted",
        "2023Q2": "Awarded"
    },
    "CIFAR BMC Winter School 2022": {
        "2022Q4": "Applied",
        "2022Q3": "Awarded"
    },
    "CIFAR BMC Winter School 2023": {
        "2023Q4": "Applied",
        "2023Q3": "Awarded"
    },
    "CIFAR Solutions Network 2022": {
        "2022Q4": "EOI",
        "2022Q3": "Selected Full Proposal",
        "2022Q2": "Final",
    }
}

export function industryOrRegional(str, companyName, replaceWith = 'regional') {
    const _replace = (capitalizedStr) => {
        const lowercase = capitalizedStr.toLowerCase();
        const titleCase = lowercase[0].toUpperCase() + lowercase.slice(1);
        return replaceAll(replaceAll(str, 'industry', lowercase), 'Industry', titleCase);
    }
    if (companyName && companyName.startsWith('OLX-')) {
        return _replace(replaceWith)
    }
    else if (companyName && isMcrockDueDiligence(companyName)) {
        return _replace('Portfolio');
    } else if (companyName && isHondaHTC(companyName)) {
        // Special handling for Honda heatmap tooltip:
        // "your industry" => "Honda", not "your Honda"
        return replaceAll(str, ['your industry', 'industry', 'Industry'], 'Honda');
    }
    return str;
}

// TODO @jamie This is awful and proper re-write of Filters needs to be done to get rid of this.
export function mapChoiceLabel(str) {
    const genderMap = {
        'Male': 'Men',
        'male': 'men',
        'Female': 'Women',
        'female': 'women'
    };
    return genderMap[str] ? genderMap[str] : str
}

export function renameEthnicity(str) {
    const map = {
        'Ethnicity': mapT(m.GROUP, "Ethnicity"),
        'ethnicity': mapT(m.GROUP, "Ethnicity").toLowerCase()
    };
    return map[str] ? map[str] : str
}

export const getDepartmentLabel = (companyName) => {
    if (isCia(companyName)) {
        return "Practice";
    }
    if (isBessemerBriefPortfolio(companyName)) {
        return "Company";
    }
    if (isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isCifarDLRL(companyName) || isCifarGlobalCallOverall(companyName) || isCifarSolutionsNetwork(companyName) || isCifarGov(companyName)) {
        return "Age Group";
    }
    if(isCifarPanCanadianAIStrategy(companyName)) {
        return "Institute";
    }
    if(isCenterbridgeLeadership(companyName)) {
        return "Company"
    }
    if (isCifarCore(companyName)) {
        return "Program";
    }
    if (isMila(companyName)) {
        return "Professor";
    }

    return mapT(m.FILTER_LABELS, "department");
}


export const getPeopleSurveyedLabel = (companyName) => {
    if (isCifarScholars(companyName) ||  isCifarDLRL(companyName) || isCifarGlobalCallOverall(companyName) || isBMCWinterSchool(companyName) || isBCIApplicants(companyName)) {
        return "People Applied";
    }

    if (isCifarGlobalCall(companyName)) {
        return "People Registered at LOI Stage"
    }

    return mapT(m.HOME_EMPLOYEE_SURVEYED, "title");
}

export const getLevelLabel = (companyName) => {
    if (isCifarScholars(companyName)) {
        return "Program";
    }
    if (isMila(companyName)) {
        return "Status";
    }
    else if (isBCIApplicants(companyName)) {
        return mapT(m.FILTER_LABELS, "lastRecruitingStage")
    }
    return mapT(m.FILTER_LABELS, "level");
}


export const getDominantGroupCellLabel = (companyName, title) => {
    if (isIllinoisShines(companyName) || isEnergySolutions(companyName)) {
        return "Majority Group";
    }
    return title;
}
