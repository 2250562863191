import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import _ from 'underscore';
import "./index.scss"
import { mapChoiceLabel } from "../../../utils/labels";
import NestedMenuItem from "material-ui-nested-menu-item/dist-web/index.js";
import set from 'lodash/set';
import without from 'lodash/without';

class SelectBox extends React.PureComponent {
    state = {
        selectedValues: [],
        isOpen: false
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.selectedValues !== props.selectedValues) {
            let selectedValues = props.selectedValues;
            if (!selectedValues) {
                selectedValues = props.multiple ? [] : "";
            }

            return {
                ...current_state,
                selectedValues: selectedValues
            };
        }
        return null
    }

    handleChange = e => {
        let selectedValues = e.target.value;
        this.setState({ selectedValues });
        this.props.onChange && this.props.onChange(selectedValues);
    };

    getStyleClass = () => {
        const { styleClass, classes } = this.props;

        /* add additional styles to this map, and to withStyles at end of file */
        const styleMap = {
            "root": classes.root,
        };

        return (styleMap[styleClass] || classes.root);
    };

    mapChoiceLabel (option) {
        if (this.props.mapChoiceLabel) {
            return this.props.mapChoiceLabel(option);
        } else {
            return mapChoiceLabel(option);
        }
    }

    _isAllSelected () {
        const { allOptions, selectedValues } = this.state;
        return allOptions.length === selectedValues.length;
    }

    _getRenderValue (values) {
        if (this.props.renderValue) {
            return this.props.renderValue(values);
        }

        if (_.isObject(_.maybe(values, 0)) && !_.isArray(_.maybe(values, 0))) {
            return _.keys(values[0]).reduce((reducer, key) => {
                if (reducer.length > 20) { return reducer; }
                return reducer + this._getRenderValue(_.isArray(values[0][key]) || _.isString(values[0][key]) ? values[0][key] : [values[0][key]])
            }, "")
        }
        else if (_.isArray(values)) {
            return values.reduce((combined, value, index) => {
                if (!value) return combined;
                combined += (index !== 0 ? ', ' : '');

                if (value.indexOf(':') === -1) {
                    combined += value;
                } else {
                    combined += value.split(':')[1]
                }

                return this.mapChoiceLabel(combined);
            }, '')
        } else {
            return this.mapChoiceLabel(values);
        }
    }

    _getSelectAllMenuItem (fields, options) {
        const {styleClass = "root"} = this.props;
        const { selectedValues: values } = this.state;
        const selectedValues = _.deepClone(values);
        const allOptions = _.deepClone(options);

        return <MenuItem value={"selectall"} onClick={e => {
            let newSelectedValues = _.deepClone(selectedValues);
            if (_.isEmpty(fields)) {
                if (_.isEqual(selectedValues.sort(), allOptions.sort())) {
                    newSelectedValues = [];
                } else {
                    newSelectedValues = allOptions;
                }
            } else {
                if (_.maybe(selectedValues, ...fields) && _.isEqual(_.maybe(selectedValues, ...fields).sort(), allOptions.sort())) {
                    newSelectedValues = set(selectedValues, fields, [])
                } else {
                    newSelectedValues = set(selectedValues, fields, allOptions);
                }
            }

            this.handleChange({target: {value: newSelectedValues}})
        }}>
            <Checkbox color={"primary"}
                      classes={{ root: this.getStyleClass(styleClass) }}
                      checked={
                          _.isArray(selectedValues)
                              ? _.isEqual(selectedValues.sort(), allOptions.sort())
                              : _.maybe(selectedValues, ...fields) && _.isEqual(_.maybe(selectedValues, ...fields).sort(), allOptions.sort())
                      }/>
            <ListItemText primary={"Select all"} />
        </MenuItem>
    }

    _getOptionMenuItem (option, fields, id) {
        const {multiple, styleClass = "root"} = this.props;
        const { selectedValues } = this.state;

        return <MenuItem key={id} value={option} onClick={e => {
            let newSelectedValues = _.clone(selectedValues);
            if (!multiple) {
                newSelectedValues = option;
            } else if (_.isEmpty(fields)) {
                if (selectedValues.includes(option)) {
                    newSelectedValues = _.without(selectedValues, option);
                } else {
                    newSelectedValues.push(option);
                }
            } else {
                if (_.maybe(selectedValues, ...fields).includes(option)) {
                    newSelectedValues = set(selectedValues, fields, without(_.maybe(selectedValues, ...fields), option))
                } else {
                    _.maybe(selectedValues, ...fields).push(option);
                    newSelectedValues = { ...selectedValues };
                }
            }

            this.handleChange({
                target: {
                    value: newSelectedValues
                }
            })
        }}>
            {
                multiple && <Checkbox color={"primary"}
                                      classes={{ root: this.getStyleClass(styleClass) }}
                                      checked={
                                          _.isArray(selectedValues)
                                              ? selectedValues.includes(option)
                                              : _.maybe(selectedValues, ...fields) && _.maybe(selectedValues, ...fields)?.includes(option)
                                      } />
            }
            <ListItemText primary={this.mapChoiceLabel(option)} />
        </MenuItem>
    }

    _renderNestedMenuItems(options, nestedFields = []) {
        const {multiple, styleClass = "root"} = this.props;
        const { selectedValues } = this.state;

        if (_.isArray(options)) {
            let renderedOptions = multiple
                ? [this._getSelectAllMenuItem(nestedFields, options)]
                : [];
            renderedOptions.push(...options.map((option, key) => this._getOptionMenuItem(option, nestedFields, key)));

            return renderedOptions;
        } else {
            return _.keys(options).map(option => {
                return <NestedMenuItem
                    label={option}
                    parentMenuOpen={this.state.isOpen}
                    onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
                >
                    {
                        this._renderNestedMenuItems(options[option], [...nestedFields, option])
                    }
                </NestedMenuItem>
            })
        }
    }

    render() {
        const { options = [], multiple = false, label = "",
        extraClasses = null, classes } = this.props;
        const { selectedValues } = this.props;
        const MenuProps = {
            variant: "menu",
            getContentAnchorEl: null,
            classes: { paper: 'filter-dropdown-container' }
        };

        return (
            <React.Fragment>
                <FormControl className={`select-box ${ extraClasses || null }`}>
                    <InputLabel htmlFor="select-multiple-checkbox">{ label || "Select Type" }</InputLabel>
                    <Select
                        multiple={multiple}
                        onClose={() => {
                            this.props.onClose && this.props.onClose()
                            this.setState({ isOpen: false })
                        }}
                        onOpen={() => {
                            this.props.onOpen && this.props.onOpen()
                            this.setState({ isOpen: true })
                        }}
                        // onChange={e => this.handleChange(e)}
                        value={_.isArray(selectedValues) || _.isString(selectedValues) ? selectedValues : [selectedValues]}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => this._getRenderValue(selected)}
                        MenuProps={MenuProps}
                    >
                        {
                            this._renderNestedMenuItems(options)
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }
}

export default withStyles({
    root: {
        color: "#6221EA !important",
        "&$checked": {
            color: "#6221EA !important"
        }
    },
    checked: {}
})(SelectBox);
