// Regex for password validation
// 1) Must have at least 1 lowercase
// 2) Must have at least 1 uppercase
// 3) Must have at least 1 special character
// 4) Must be 8-128 character length
const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');

const individualRegex = [
    {
        regex: '^(?=.*[a-z])',
        message: 'Password must contain at least 1 lowercase alphabetical character'
    },
    {
        regex: '^(?=.*[A-Z])',
        message: 'Password must contain at least 1 uppercase alphabetical character'
    },
    {
        regex: '^(?=.*[0-9])',
        message: 'Password must contain at least 1 numeric character'
    },
    {
        regex: '^(?=.*[!@#$%^&*])',
        message: 'Password must contain at least 1 special character'
    },
    {
        regex: '^(?=.{8,128})',
        message: 'Password must be between 8-128 characters long'
    }
];

export function validatePassword(password){
    return individualRegex.map(check=>{
        const regex = new RegExp(check.regex);
        if(regex.test(password)){
            return null
        }
        return check.message;
    }).filter(x=> x!== null);
}