/** @format */

export const toTitleCase = (str) => {
  /**
   * Capitalizes each word in the string provided, returns empty string if str variable is empty
   */
  if (str === null || str === undefined) return "";
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
