import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const TooManyFilters = styled.div`
    margin-top: 16vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`

export const Container = styled.div`
    width: 650px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

export const Icon = styled.div`
    width: 58px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary20};
`

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const Description = styled.p`
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
`

export const Button = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.primary50};
    cursor: pointer;

    ${getFontStyle('p01Underline')}
`
