/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 **/
import { combineReducers } from 'redux';
import app from './app';
import groups from './groups';
import colors from './colors';
import analyze from './analyze';
import connect from './connect';
import auth from './auth';
import home from './home';
import message from './message';
import kpimap from './kpimap';
import profile from "./profile";
import communications from "./communications";
import survey from "./survey";
import ilndashboard from "./ilndashboard";
import solutionAllPrograms from "./solutionAllPrograms"
import solutionGoals from "./solutionGoals"
import solutionMySolutions from "./solutionMySolutions"
import solutionMySolutionsArchive from "./solutionMySolutionsArchive"
import solutionRecommended from "./solutionRecommended"
import bespoke from './bespoke'

const appReducer = combineReducers({
    /* Reducer aggregator */
    app,
    analyze,
    auth,
    colors,
    communications,
    connect,
    bespoke,
    groups,
    home,
    kpimap,
    message,
    profile,
    survey,
    ilndashboard,
    solutionAllPrograms,
    solutionGoals,
    solutionMySolutions,
    solutionMySolutionsArchive,
    solutionRecommended,
  })

  const rootReducer = (state, action) => {
    // Clear Redux store when a user signs out
    return appReducer(state, action)
  };


export default rootReducer
