import React, { Component } from 'react';
import { Row, Column } from '../../utils/FlexBox';
import FeedbackCard from './FeedbackCard';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import * as m from '../../../translations/mapping';
import {getTranslation, mapT} from '../../../translations/utils';
import MainCard from "../../utils/MainCard";
import { kpiFormatter } from '../../../utils';
import { isPortfolioCompany } from "../../../utils/companyChecks";
import { FreeBarChart } from './FreeBarChart'
import { FreeLegend } from './FreeLegend'
import './index.scss';
const PNTS = "prefer not to answer"

// This is used to match up against the backend payload that is sent
// There is another similar mapping object in the render method that is almost
// identical, with the main difference being it uses translations for displaying the data to
// the user
const demographicMapping = {
    "gender": {
        "dom": "Male",
        "non_dom": "Female+",
        "pnts": PNTS
    },
    "ethnicity": {
        "dom": "White",
        "non_dom": "POC",
        "pnts": PNTS
    },
    "sexual_orientation": {
        "dom": "Heterosexual",
        "non_dom": "LGBTQ+",
        "pnts": PNTS
    },
    "disability": {
        "dom": "People without disabilities",
        "non_dom": "People with disabilities",
        "pnts": PNTS
    },
}

class Feedback extends Component {
    state = {
        category: 'All',
        demographic: '',
        demographicName: '',
        overallDemographicA: '',
        overallDemographicB: '',
        overallDemographicC: '',
        demographicA: '',
        demographicB: '',
        demographicC: '',
        errorShown: false
    };

    componentDidUpdate (prevState) {
        // We check to see if the number of respondents is above the minimum threshold and if it isn't, display an
        // error message.
        const { freeText } = this.props
        const { category, errorShown } = this.state

        if (prevState.category !== category) {
            const barGraphData = this.formatBarGraphData(freeText, category)
            const showGraph = barGraphData.length > 0
            const isAllCategory = category === 'All'

            if (!showGraph && !isAllCategory && !errorShown) {
                this.props.showMessage({ type: "error", message: "Data cannot be displayed, minimum threshold not met." })
                this.setState({
                    errorShown: true
                })
            }
        }
    }

    changeCategory = (category) => {
        const barGraphData = this.formatBarGraphData(this.props.freeText, category)
        const bar = barGraphData[0]
        if (_.isEmpty(bar)) {
            this.setState({
                category,
                errorShown: false
            })
            return null;
        }

        // Each time the category changes we reset back to the first key/value pair which is Men/Women
        const groupName = barGraphData[0]['name']

        this.setState({
            category,
            demographic: bar.name,
            demographicName: groupName,
            demographicA: bar.demographic_a,
            demographicB: bar.demographic_b,
            demographicC: bar.demographic_c,
            overallDemographicA: bar.overall_demographic_a,
            overallDemographicB: bar.overall_demographic_b,
            overallDemographicC: bar.overall_demographic_c
         })
    }

    categoryToButton(category) {
        return (
            <button
                key={category}
                className={`employees-feedback__categories__category-button ${this.state.category === category ? 'selected' : ''}`}
                onClick={() => this.changeCategory(category)}
            >
                {mapT(m.EMPLOYEE_FEEDBACK_CATEGORIES, category)}
            </button>
        )
    }

    feedbackToCard({ text, title, translated_text }, index) {
        return (
            <FeedbackCard key={index} label={title} feedback={translated_text ? translated_text : text} />
        )
    }

    fillMissingTitles(freetext) {
        if (freetext.title === undefined || freetext.title === '') {
            freetext.title = freetext.type;
        }
        return freetext
    }

    getPpDesc(category) {
        if (category === 'All') {
            return null;
        }
        return this.props.freeText[category]['description'];
    }

    getPpKpi(category) {
        if (category === 'All') {
            return null;
        }

        const kpiValue = this.props.freeText[category]['kpi'];
        return kpiFormatter(kpiValue);
    }

    getTitle = () => {
        const t = this.props.t;
        if (isPortfolioCompany(this.props.companyName)) {
            return t("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.title_p");
        }

        return getTranslation("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.title", this.props.companyName);
    }

    getNlpSubtitle = () => {
        const t = this.props.t;
        if (isPortfolioCompany(this.props.companyName)) {
            return t("ANALYZE.FEEDBACK.NLP_SUBTITLE_p")
        }

        return getTranslation("ANALYZE.FEEDBACK.NLP_SUBTITLE", this.props.companyName);
    }

    formatBarGraphData = (freeText, category) => {
        // Commit that has the logic for displaying "All" in the barchart can be found here
        // https://github.com/DiversioTeam/Diversio-Frontend/blob/22abe4f1543d64feb70fbb67bbe1cae94e940aa5/src/components/Analyze/Feedback/index.js#L142
        if (_.isEmpty(freeText)) {
            return [];
        }

        let freeTextData
        let result = []

        if (category !== 'All') {
            freeTextData = freeText[category] && freeText[category]['demographics']

            // First we loop over the 4 different dimensions gender/ethnicity/sexual_orientation/disability
            for (var i in freeTextData) {
                let demographicA, demographicB, demographicC
                const demographics = freeTextData[i]['demographics']
                const dimension = freeTextData[i]['dimension'].toLowerCase()

                // For each dimension we iterate through it's values to assign dominant and non dominant values
                for (var j in demographics) {
                    if (j.toLowerCase() === demographicMapping[dimension]["dom"].toLowerCase()) {
                        demographicA = demographics[j]
                    }

                    if (j.toLowerCase() === demographicMapping[dimension]["non_dom"].toLowerCase()) {
                        demographicB = demographics[j]
                    }

                    if (j.toLowerCase() === 'prefer not to answer') {
                        demographicC = demographics[j]
                    }
                }

                // We create the newly formatted object to add to an array
                let obj = {
                    name: freeTextData[i]['dimension'],
                    demographic_a: demographicA,
                    demographic_b: demographicB,
                    demographic_c: demographicC
                }
                result.push(obj)
            }
        }

        // Now we find the overall demographic percentages
        const companyOverview = this.props.diversityComposition

        // Loop over the different dimensions (Gender/Ethnicity/Sexual Orientation/Disability)
        for (var i in companyOverview) {
            // For each dimension we loop over the 3 values provided in the dimension object
            for (var j in companyOverview[i]) {

                const demoArr = companyOverview[i][j]
                const name = j.replace(" ", "_").toLowerCase()
                let demographicA, demographicB, demographicC

                // We then loop through the 3 dimension objects to assign them dominant/non dominant values
                // To display in the Barchart
                for (var y in demoArr) {
                    const value = demoArr[y]['value']
                    if(demoArr[y]['name'] == demographicMapping[name]["dom"]) {
                        demographicA = value
                    }

                    if(demoArr[y]['name'] == demographicMapping[name]["non_dom"]) {
                        demographicB = value
                    }

                    if(demoArr[y]['name'].toLowerCase() == demographicMapping[name]["pnts"]) {
                        demographicC = value
                    }
                }
                // Loop over the result object created earlier and add the overal percentages of each demographic
                // to the object
                for (var z in result) {
                    const resultName = result[z]['name']
                    if (resultName === name) {
                        result[z]['overall_demographic_a'] = demographicA == undefined ? "N/A" : demographicA.toString()
                        result[z]['overall_demographic_b'] = demographicB == undefined ? "N/A" : demographicB.toString()
                        result[z]['overall_demographic_c'] = demographicC == undefined ? "N/A" : demographicC.toString()
                    }
                }
            }
        }
        return result
    }

    handleBarMouseOver = (barData) => {
        if (_.isEmpty(barData)) {
            return null;
        }

        if (barData['activePayload']) {
            const bar = barData['activePayload'][0]['payload']
            const barGraphData = this.props.freeText[this.state.category]['demographics']
            let currentDemographic

            // Loop over the bargraph data's different dimensions to match which dimension
            // the user is hovering over, so we know what data to pull and display from the constant barGraphData
            for (var i in barGraphData) {
                if (barGraphData[i]['dimension'] === bar.name) {
                    currentDemographic = barGraphData[i]['dimension']
                }
            }

            this.setState({
                demographic: bar.name,
                demographicName: currentDemographic,
                demographicA: bar.demographic_a,
                demographicB: bar.demographic_b,
                demographicC: bar.demographic_c,
                overallDemographicA: bar.overall_demographic_a,
                overallDemographicB: bar.overall_demographic_b,
                overallDemographicC: bar.overall_demographic_c
            })
        }
    }

    render() {
        const { t , freeText } = this.props;
        let colorIndexA = 0;
        let secondaryColorIndexA = 0;

        let colorIndexB = 0;
        let secondaryColorIndexB = 0;

        let colorIndexC = 0;
        let secondaryColorIndexC = 0;

        const colors = [
            "#CFBCF8",
            "#B6BFF6",
            "#F8ABD6",
            "#FBC02D"
        ]

        const secondaryColors = [
            "#6221EA",
            "#0725E8",
            "#DE425B",
            "#FF811D"
        ]

        const {
            category,
            demographic,
            demographicName,
            demographicA,
            demographicB,
            demographicC,
            overallDemographicA,
            overallDemographicB,
            overallDemographicC
        } = this.state;

        if (_.isEmpty(freeText)) {
            return null;
        }

        const freeTextCategories = Object.keys(freeText).sort((a, b) => {
            if (freeText[a]['free_texts'].length > freeText[b]['free_texts'].length) {
                return -1;
            }
            return 1;
        });

        const categories = ['All', ...freeTextCategories];
        const categoryButtons = categories.map(this.categoryToButton.bind(this));

        const allFeedback = [];

        for (const cat of freeTextCategories) {
            allFeedback.push(...freeText[cat]['free_texts'])
        }

        let activeFeedback;

        if (category === 'All') {
            activeFeedback = allFeedback;
        } else {
            activeFeedback = freeText[category]['free_texts'];
        }

        const feedbackCards = activeFeedback.map(this.feedbackToCard.bind(this));

        const barGraphData = this.formatBarGraphData(freeText, category)
        const showGraph = barGraphData.length > 0
        const isAllCategory = category === 'All'

        // Used to display values in the Barchart X axis
        const translationMapping = {
            "gender": {
                "dom": t("GROUP.GENDER.DOM"),
                "non_dom": t("GROUP.GENDER.NONDOM"),
                "pnts": PNTS
            },
            "ethnicity": {
                "dom": t("GROUP.ETHNICITY.DOM"),
                "non_dom": t("GROUP.ETHNICITY.NONDOM"),
                "pnts": PNTS
            },
            "sexual_orientation": {
                "dom": t("GROUP.SEXUAL_ORIENTATION.DOM"),
                "non_dom": t("GROUP.SEXUAL_ORIENTATION.NONDOM"),
                "pnts": PNTS
            },
            "disability": {
                "dom": t("GROUP.DISABILITY.DOM"),
                "non_dom": t("GROUP.DISABILITY.NONDOM"),
                "pnts": PNTS
            },
        }

        // Formatting to display the dom and non dom values in the Legend
        const formatName = demographicName.toUpperCase()
        const freeLegendDom = t(`GROUP.${formatName}.DOM`)
        const freeLegendNonDom = t(`GROUP.${formatName}.NONDOM`)

        const freeLegendNames = {
            "dom": freeLegendDom,
            "non_dom": freeLegendNonDom
        }
        return (
            <MainCard cypressId={"employee-free-texts"} title={this.getTitle()}
                toolTipContent={t("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.tooltip")}>

                <div id="feedback" className={"employees-feedback"}>
                    <h2 className="employees-feedback__subtitle">{this.getNlpSubtitle()}</h2>
                    <div className="employees-feedback__container">
                        <Row>
                            <Column width="100%">
                                <h3 className={"employees-feedback__painpoint-label"}>{t("ANALYZE.FEEDBACK.TOP_PAINPOINTS_LABEL")}</h3>
                            </Column>
                        </Row>
                        <div className="employees-feedback__categories">
                            {categoryButtons}
                        </div>
                        <hr />
                        <h2 className="employees-feedback__subtitle">{this.getPpDesc(category)}</h2>
                        {showGraph && !isAllCategory && <div className="employees-feedback__barchart">
                            <div className="analyze-diversity-snapshot__detail-box">
                                <div className="employees-feedback__barchart-legend">
                                    <div className="barchart-legend-demographic">
                                        <h3>{demographic.replace("_", " ")}</h3>
                                    </div>
                                    <FreeLegend
                                        demographic={demographic}
                                        demographicA={demographicA}
                                        demographicB={demographicB}
                                        demographicC={demographicC}
                                        overallDemographicA={overallDemographicA}
                                        overallDemographicB={overallDemographicB}
                                        overallDemographicC={overallDemographicC}
                                        demographicName={freeLegendNames}
                                    />
                                </div>
                            </div>
                            <div className="employees-feedback__barchart_header">
                                <span className={"employees-feedback__barchart__label"}>{t("ANALYZE.FEEDBACK.PAINPOINT_BY_DEMOGRAPHIC")} <span>{category}</span></span>
                                <span className={"employees-feedback__barchart__label employees-feedback__barchart__kpi"}>{
                                    this.getPpKpi(category) ?
                                        (<>Relevant KPI: <span>{this.getPpKpi(category)}</span></>)
                                        : null
                                }
                                </span>
                            </div>
                            <div className={"employees-feedback__barchart__container"}>
                                <FreeBarChart
                                    barGraphData={barGraphData}
                                    groups={translationMapping}
                                    colors={colors}
                                    colorIndexA={colorIndexA}
                                    colorIndexB={colorIndexB}
                                    colorIndexC={colorIndexC}
                                    secondaryColors={secondaryColors}
                                    secondaryColorIndexA={secondaryColorIndexA}
                                    secondaryColorIndexB={secondaryColorIndexB}
                                    secondaryColorIndexC={secondaryColorIndexC}
                                    onMouseMove={bar => this.handleBarMouseOver(bar)}
                                />
                            </div>
                        </div>}
                        {showGraph && !isAllCategory && <hr />}
                        <div className="employees-feedback__comments">
                            {/*<p className={"employees-feedback__comments__label"}>{t("ANALYZE.FEEDBACK.SAMPLE_COMMENTS")} <span>{category}</span></p>*/}
                            <div className={"employees-feedback__comments__container"}>
                                {feedbackCards}
                            </div>
                        </div>
                    </div>
                </div>
            </MainCard>
        );
    }
}

export default withTranslation()(Feedback);
