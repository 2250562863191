import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Toast } from '@diversioteam/diversio-ds'

import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useUserInformation } from 'hooks/useUserInformation'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useVersion } from 'hooks/useVersion'
import { AppRoute } from 'routing/AppRoute.enum'
import { useGetInclusionScoresSkeleton } from 'hooks/inclusion/useGetInclusionScoresSkeleton'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'

import { PeopleSurveyed } from './PeopleSurveyed'
import { InclusionScore } from './InclusionScore'
import { InspirationalQuotes } from './InspirationalQuotes'
import { BannerCarousel } from './BannerCarousel'
import { Onboarding } from './Onboarding'
import { DiversityBreakdownByRole } from './DiversityBreakdownByRole'
import { InclusionInsights } from './InclusionInsights'
import { InclusionHeatmap } from './InclusionHeatmap'
import { DiversityBreakdown } from './DiversityBreakdown'
import { SurveyParticipation } from './SurveyParticipation'
import { SidePanel } from './SidePanel'
import { Nps } from './Nps'
import * as S from './home.styles'

export const HomeV2 = () => {
    const [isOnboardingVisible, setIsOnboardingVisible] = useState(false)
    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false)

    const versionsContext = useVersion()

    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2

    const { enableDebugMode } = useUserInformation()

    const userMetaData = useUserMetadata()
    const hasAnalyzePermissions = !!userMetaData.data?.currentCompany?.permissions.analyze

    const queryGetCompanyFilters = useGetCompanyFilters()

    const hasDiversity = !!queryGetCompanyFilters.data?.hasDiversityQuestions
    const hasInclusion = !!queryGetCompanyFilters.data?.hasInclusionQuestions

    useGetInclusionScoresSkeleton({ key: 'target_groups' }, { enabled: hasAnalyzePermissions })

    const isReturningUser = Boolean(localStorage.getItem('home_v2_onboarding_complete'))

    const handleChangeDisclaimerVisibility = (show: boolean) => {
        setIsDisclaimerVisible(show)
    }

    if (!isV2Enabled && !enableDebugMode) {
        return <Redirect to={generateCompanyURI(AppRoute.HomeV1)} />
    }

    return (
        <S.HomeWrapper
            isDisclaimerVisible={isDisclaimerVisible}
            hasDiversity={hasDiversity}
            hasInclusion={hasInclusion}
        >
            {isOnboardingVisible && <Onboarding onComplete={() => setIsOnboardingVisible(false)} />}

            <S.Content>
                <S.SecondaryColumn>
                    {(hasInclusion || queryGetCompanyFilters.isLoading) && <InclusionScore />}

                    <SurveyParticipation />
                    <PeopleSurveyed />

                    <Nps />

                    {(hasDiversity || queryGetCompanyFilters.isLoading) && <InspirationalQuotes />}
                </S.SecondaryColumn>

                <S.PrimaryColumn>
                    <BannerCarousel
                        isFirstLogin={!isReturningUser}
                        handleShowOnboarding={() => setIsOnboardingVisible(true)}
                        onViewDisclaimer={() => handleChangeDisclaimerVisibility(true)}
                    />

                    {(hasDiversity || queryGetCompanyFilters.isLoading) && (
                        <>
                            <DiversityBreakdown />
                            <DiversityBreakdownByRole />
                        </>
                    )}

                    {(hasInclusion || queryGetCompanyFilters.isLoading) && <InclusionHeatmap />}

                    {((hasInclusion && hasDiversity) || queryGetCompanyFilters.isLoading) && <InclusionInsights />}

                    {hasInclusion && !hasDiversity && <InspirationalQuotes />}
                </S.PrimaryColumn>
            </S.Content>

            <SidePanel />

            <Toast
                type="warning"
                variant="bulky"
                message="Laws and regulations governing discrimination, harassment and personally identifiable information are in constant flux. Diversio attempts to do it’s best to utilize the most current legal developments but we do not guarantee or warrant this information is the most up-to-date and accurate. Nothing herein shall be construed as professional advice as to any particular situation or constitute a legal opinion with respect to compliance with any law or other directive. Transmission of the information is not intended to create, and receipt does not constitute an attorney-client relationship. You should not act upon this information without seeking independent legal advice."
                onClick={() => handleChangeDisclaimerVisibility(false)}
                open={isDisclaimerVisible}
                data-testid="diclaimer-toast"
            />
        </S.HomeWrapper>
    )
}
