import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { ChevronRightIcon } from '@diversioteam/diversio-ds'

import { AppRoute } from './../../../routing/AppRoute.enum'
import { RegionSwitch } from './../RegionSwitch'
import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { loginSchema } from './login.schema'
import { LoginFields, LoginFormData, LoginProps } from './login.types'
import Link from './../../utils/Link'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './login.styles'

export const Login = ({ isLoading, isSuccess, data, isError, error, onSubmit }: LoginProps) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
    })

    const handleSubmitCallback = useCallback(
        async (data: LoginFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: LoginFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.FormWrapper>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    data={data}
                    isError={isError}
                    error={error}
                    translationPrefix="AUTH.LOGIN"
                />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.LOGIN.TITLE')}</S.Title>

                <S.Fields>
                    <S.TextField
                        id={LoginFields.username}
                        inputRef={register}
                        error={Boolean(errors[LoginFields.username])}
                        helperText={getErrorMessage(LoginFields.username)}
                        name={LoginFields.username}
                        label={t('AUTH.LOGIN.USERNAME_EMAIL')}
                        type="text"
                        disabled={isLoading}
                        fullWidth
                    />

                    <S.TextField
                        id={LoginFields.password}
                        inputRef={register}
                        error={Boolean(errors[LoginFields.password])}
                        helperText={getErrorMessage(LoginFields.password)}
                        name={LoginFields.password}
                        label={t('AUTH.LOGIN.PASSWORD')}
                        type="password"
                        disabled={isLoading}
                        fullWidth
                    />
                </S.Fields>

                <S.Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.LOGIN.LOGIN')}
                </S.Button>

                <S.SsoButton as={Link} to={{ pathname: AppRoute.Sso, search: location.search }}>
                    {t('AUTH.SSO.LOGIN_WITH_SSO')}
                </S.SsoButton>

                <S.LinksGroup>
                    <RegionSwitch />

                    <S.ForgotPassword as={Link} to={{ pathname: AppRoute.ForgotPassword, search: location.search }}>
                        {t('AUTH.LOGIN.FORGOT_PASSWORD')}
                    </S.ForgotPassword>
                </S.LinksGroup>

                <Footer />
            </S.Form>
        </S.FormWrapper>
    )
}
