import { TargetGroup } from 'types/common'

export const getCleanedGroups = (targetGroups: TargetGroup[]) => {
    const cleanedGroups = new Set()

    for (const i in targetGroups) {
        cleanedGroups.add(targetGroups[i].name)
    }

    return Array.from(cleanedGroups)
}
