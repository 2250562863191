import { useContext } from 'react'

import { BreadcrumbsContext } from 'context/breadcrumbs/breadcrumbsContext/BreadcrumbContext'

export const useBreadcrumbs = () => {
    const context = useContext(BreadcrumbsContext)

    if (context === undefined) {
        throw new Error('BreadcrumbsContext must be within BreadcrumbsContextController')
    }

    return context
}
