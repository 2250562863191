import React from 'react'
import { NpsCard, NpsCardSkeleton } from '@diversioteam/diversio-ds'

import { useGetNps } from 'hooks/useGetNps'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const Nps = () => {
    const { data, isLoading } = useGetNps()
    const { survey } = useSurveys()

    if (isLoading || !survey) {
        return <NpsCardSkeleton data-testId="nps-skeleton" />
    }

    if (data?.data && survey) {
        return (
            <NpsCard
                results={data.data}
                selectedSurvey={{
                    year: survey.year,
                    quarter: survey.quarter,
                    yearQuarterLabel: survey.name,
                }}
                data-testId="nps"
            />
        )
    }

    return null
}
