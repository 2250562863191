import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { getNps } from 'api/actions/home/nps/npsActions'
import { GetNpsResponse } from 'api/actions/home/nps/npsActions.types'

import { useQuery } from './useQuery'

export const useGetNps = () => {
    const query = useQuery<GetNpsResponse, AxiosError>({
        queryKey: [Queries.getNps],
        queryFn: getNps,
        select: (data) => ({
            data: [...data.data].reverse(),
        }),
    })

    return query
}
