import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import Dashboard from 'containers/Dashboard'
import ErrorPage from 'components/ErrorPages'
import SelfServeRegister from 'containers/Auth/SelfServeRegister'
import Register from 'containers/Auth/Register'

import { ChooseDashboard } from 'containers/ChooseDashboard'
import { LoginContainer } from 'containers/Auth/Login'
import { ForgotPasswordContainer } from 'containers/Auth/ForgotPassword'
import { ResetPasswordContainer } from 'containers/Auth/ResetPassword'
import { SendVerificationCodeContainer } from 'containers/Auth/SendVerificationCode'
import { VerifyEmailCodeContainer } from 'containers/Auth/VerifyEmailCodeContainer'
import { VerifyBackupCodeContainer } from 'containers/Auth/VerifyBackupCodeContainer'
import { ScheduledMaintenance } from 'components/Auth/ScheduledMaintenance'
import { AuthBillboard } from 'hoc/AuthBillboard'
import { AppRoute } from 'routing/AppRoute.enum'
import { SSOContainer } from 'containers/Auth/Sso'

export const AppRoutes = () => {
    const queryClient = useQueryClient()

    return (
        <Switch>
            <Route path={[AppRoute.Login, AppRoute.Sso]}>
                <AuthBillboard>
                    <Route path={AppRoute.Login} component={LoginContainer} />
                    <Route path={AppRoute.Sso} component={SSOContainer} />
                </AuthBillboard>
            </Route>

            <Route path={AppRoute.SsoCallback} component={SSOContainer} />
            <Route path={AppRoute.Register} component={Register} />
            <Route path={AppRoute.SelfServeRegister} component={SelfServeRegister} />
            <Route path={AppRoute.ForgotPassword} component={ForgotPasswordContainer} />
            <Route path={AppRoute.ResetPassword} component={ResetPasswordContainer} />
            <Route path={AppRoute.SendVerificationCode} component={SendVerificationCodeContainer} />
            <Route path={AppRoute.TwoStepVerification} component={VerifyEmailCodeContainer} />
            <Route path={AppRoute.TwoStepVerificationBackup} component={VerifyBackupCodeContainer} />
            <Route path={AppRoute.ScheduledMaintenance} component={ScheduledMaintenance} />
            <Route path={AppRoute.ChooseDashboard} component={ChooseDashboard} />

            <Route path={AppRoute.Error} component={ErrorPage} />

            <Route
                path={AppRoute.Default}
                render={(props) => <Dashboard {...(props as any)} queryClient={queryClient} {...props} />}
            ></Route>
        </Switch>
    )
}
