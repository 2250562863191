import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const Diversity = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .recharts-tooltip-wrapper {
        z-index: 2000;
    }
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`

export const LeftBox = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 251px;
`

export const RightBox = styled.div`
    flex: 1;
    width: calc(100% - 250px);
    min-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${({ theme }) => theme.columnsSpacing.sm.margin}px;

    ${media.lg} {
        padding: ${({ theme }) => theme.columnsSpacing.lg.margin}px;
    }
`

export const Header = styled.div`
    margin-bottom: 24px;
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dds-input-button {
        padding-right: 10px;
    }

    .dds-single-select {
        &:only-child {
            margin-left: auto;
        }
    }
`

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const Subtitle = styled.h3`
    margin-top: 2px;

    ${getFontStyle('subheaderMedium')}
`
