import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { parseYearQuarter } from 'utils'

import { getInclusionScoresSkeleton } from 'api/actions/analyze/inclusionData/inclusionDataActions'
import {
    GetInclusionScoresSkeletonParams,
    GetInclusionScoresSkeletonResponse,
} from 'api/actions/analyze/inclusionData/inclusionDataActions.types'
import { Queries } from 'api/actions/analyze/inclusionData/queries'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export type GetInclusionScoresSkeletonDefaultParams = Omit<GetInclusionScoresSkeletonParams, 'year'>

export const useGetInclusionScoresSkeleton = (
    defaultParams: GetInclusionScoresSkeletonDefaultParams,
    options?: { enabled: boolean },
) => {
    const { survey } = useSurveys()

    const surveyParam = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const params = {
        ...defaultParams,
        ...surveyParam,
    }

    const query = useQuery<GetInclusionScoresSkeletonResponse, Error>({
        queryKey: [Queries.getInclusionScoresSkeleton, params],
        queryFn: () => getInclusionScoresSkeleton(params),
        keepPreviousData: true,
        enabled: options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}
