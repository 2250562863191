import React from 'react'
import { Link as RouterLink, Redirect } from 'react-router-dom'
import { Button } from '@diversioteam/diversio-ds'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'

import { useUserInformation } from 'hooks/useUserInformation'
import { useGetActiveCompanyInformation } from 'hooks/useGetActiveCompany'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { RootState } from 'reducers/rootState.types'
import { Company } from 'api/actions/useMetadata/userMetadataActions.types'
import TAXONOMIES from 'utils/taxonomies'

import * as S from './chooseDashboard.styles'
import diversioLogo from './../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'

export const ChooseDashboard = () => {
    const { isAuthenticated } = useUserInformation()
    const { data: userMetadata } = useUserMetadata()

    const { companyInformation } = useGetActiveCompanyInformation()
    const home = useSelector((state: RootState) => state.home)

    const companyList = userMetadata?.companies
    const permissions = companyInformation?.permissions
    const choseCompany = !!home?.choseCompany

    const handleClickCompany = (selectedCompany: Company) => {
        const { name } = selectedCompany

        mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
            dashboardName: name,
            user: userMetadata?.username,
            action: 'dashboard switch',
        })
    }

    if (!isAuthenticated) {
        return <Redirect to={AppRoute.Login} />
    }

    if (isAuthenticated && permissions && choseCompany) {
        if (permissions.ilnDashboardHome) return <Redirect to={generateCompanyURI(AppRoute.IlnDashboardHome)} />
        if (permissions.home) return <Redirect to={generateCompanyURI(AppRoute.Home)} />
        if (permissions.survey) return <Redirect to={generateCompanyURI(AppRoute.Survey)} />
        if (permissions.solutionGoals) return <Redirect to={generateCompanyURI(AppRoute.SolutionGoals)} />

        return <Redirect to={generateCompanyURI(AppRoute.Default)} />
    }

    return (
        <S.ChooseDashboard>
            <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

            <S.Title>Choose your dashboard</S.Title>

            <S.List>
                {companyList
                    ? companyList.map((company) => {
                          const name = company.name

                          return (
                              <S.ListItem key={name}>
                                  <Button
                                      color="secondaryLight"
                                      size="large"
                                      fullWidth
                                      onClick={() => handleClickCompany(company)}
                                      component={RouterLink}
                                      to={`/company/${company.uuid}${AppRoute.Home}`}
                                  >
                                      {name}
                                  </Button>
                              </S.ListItem>
                          )
                      })
                    : Array.from(Array(5).keys()).map((index) => (
                          <S.ListItem key={index}>
                              <S.ButtonSkeleton variant="rect" />
                          </S.ListItem>
                      ))}
            </S.List>
        </S.ChooseDashboard>
    )
}
