import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { TextLink } from './../auth.styles'

export const SsoButton = styled.a`
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary60};

    ${getFontStyle('p02Medium')}
`

export const ForgotPassword = styled(TextLink)`
    margin: 0;
`

export const LinksGroup = styled.div`
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export {
    Billboard,
    Button,
    Container,
    Form,
    FormWrapper,
    Logo,
    Title,
    Fields,
    TextField,
    Toast,
} from './../auth.styles'
