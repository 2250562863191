/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { stringify } from "query-string";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import {
  Toast,
  BannerCard,
  Button,
  SaveIcon,
  GoalCard,
  ToolCard,
  PeopleIcon,
  ProgramCell,
  SavedProgram,
  ActiveProgram,
  CheckmarkIcon,
  YourGoalsCard,
  GoalsCarousel,
  ChevronRightIcon,
  GoalsCarouselSkeleton,
} from "@diversioteam/diversio-ds";
import { Box, Fade, Modal, Backdrop } from "@material-ui/core";

import { SavedProgramListSkeleton } from "components/skeletons/savedProgramListSkeleton";
import { ActiveProgramListSkeleton } from "components/skeletons/activeProgramListSkeleton";
import { YourGoalsListSkeleton } from "components/skeletons/yourGoalsListSkeleton";
import { ToolCardListSkeleton } from "components/skeletons/toolCardListSkeleton";
import { Actions } from "actions";
import { getActiveCompany, getActiveCompanyID, objectFlip } from "utils";
import { getPageName, handleClickTool, mapProgramToTracking } from "utils/tracking";
import { kpiMap, mapIcons } from "utils/kpiUtils";
import { toTitleCase } from "utils/programFeaturesFunctions";
import { solutionUrls } from "dashboardRoutes";
import Link from "components/utils/Link";
import _ from "lodash"
import TAXONOMIES from "utils/taxonomies";
import { MyReports } from "../MySolutions/MyReports";
import { NO_DATA } from "components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils";
import { withHooks } from "config/withHooks/withHooks";
import { getCleanedKpiCodes } from "utils/getCleanedKpiCodes";
import { getCleanedGroups } from "utils/getCleanedGroups";
import "./index.scss";

const TIME_MAP = {
  93: "3 Months",
  186: "6 Months",
  279: "9 Months",
  365: "1 Year",
  730: "2 Years",
  1095: "3 Years",
};

const PAGE_NAME = "my-solutions";

class MySolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGoalIndex: 0,
      showCreateGoalCard: false,
      selectedImprovementScore: null,
      selectedKPI: null,
      selectedTargetGroup: null,
      selectedTimeline: null,
      selectedGoalString: null,
      deleteModal: false,
      goalToBeDeleted: null,
      modalOpen: false,
      program: null,
    };
    this.props.resetGlobalToast();
    this.ref = React.createRef();

    this.props.getSolutionMyGoalsV2();
    this.props.getSolutionMyProgramsV2();
    this.props.getSolutionMyToolsV2();
    this.props.getSolutionMySolutionFiltersV2();
    this.props.getSolutionGoalsOptionsV2();
    this.props.getSolutionGoalsInsightDataV2();
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME)
    })
  }

  componentDidUpdate() {}

  handleSelectedGoal = (newGoalIndex) => {
    this.setState({
      selectedGoalIndex: newGoalIndex,
      showCreateGoalCard: false,
      deleteModal: false,
      goalToBeDeleted: null,
    });
    this.ref.current?.onSlideTo(newGoalIndex);
  };

  handleOpenUpdateGoal = (goalInfo) => {
    const { myGoals } = this.props.solutionMySolutions;

    const goal = myGoals[goalInfo.index];

    this.setState({
      showCreateGoalCard: true,
      selectedKPI: goal.kpiPayload[0].code,
      selectedTargetGroup: goal.targetGroups[0].name,
      selectedTimeline: TIME_MAP[goal.duration.split(" ")[0]],
      selectedImprovementScore: parseFloat(goal.pointImprovement),
    });
  };

  handleGoalCardUpdate = () => {
    /**
     * Called to create a new goal based off the user selected values
     */
    const {
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      selectedGoalIndex,
    } = this.state;
    const { insightData, options } = this.props.solutionGoals;
    const { kpiValues } = options;
    const { myGoals } = this.props.solutionMySolutions;
    const valueCheck = [
      {
        label: "Please select an Improvement Score before continuing!",
        value: selectedImprovementScore,
      },
      {
        label: "Please select a KPI before continuing!",
        value: selectedKPI,
      },
      {
        label: "Please select a Target Group before continuing!",
        value: selectedTargetGroup,
      },
      {
        label: "Please select a Timeline before continuing!",
        value: selectedTimeline,
      },
    ];

    // Check to see if any required values were not selected
    for (const i in valueCheck) {
      const row = valueCheck[i];
      if (!row.value) {
        this.props.showToast({ type: "error", message: row.label });
        return;
      }
    }

    let kpi = kpiValues.filter((kpi) => kpi.code === selectedKPI)[0];

    const targetGroups = insightData.targetGroup;
    const tg_array = [];
    for (const i in Object.keys(targetGroups)) {
      if (targetGroups[i].name === selectedTargetGroup) {
        tg_array.push(targetGroups[i].id);
        break;
      }
    }

    const updatedGoal = {
      point_improvement: selectedImprovementScore,
      kpis: [kpi.id],
      target_groups: tg_array,
      duration: parseFloat(options.timelineMapping[selectedTimeline]),
      survey: insightData.surveyId,
      newKpi: kpi,
    };

    const payload = {
      id: myGoals[selectedGoalIndex].id,
      data: updatedGoal,
    };

    const selectedKpiCode = mapping.score_card_mapping[selectedKPI];

    mixpanel.track(TAXONOMIES.MYSOLUTIONS_UPDATE_GOAL, {
      pointImprovementChoice: selectedImprovementScore,
      kpiChoice: selectedKpiCode,
      targetGroupChoice: selectedTargetGroup,
      timeFrameChoice: selectedTimeline
    })

    this.props.doSolutionMySolutionUpdateGoal(payload);
  };

  handleParent = (key, value) => {
    /**
     * Function to be called to update the goal card's parent state
     */

    switch (key) {
      case "pointImprovement":
        return this.setState({
          selectedImprovementScore: parseFloat(value),
        });
      case "kpi":
        return this.setState({
          selectedKPI: value,
        });
      case "targetGroup":
        return this.setState({
          selectedTargetGroup: value,
        });
      case "timeline":
        return this.setState({
          selectedTimeline: value,
        });
    }
  };

  handleGoalDelete = (goalToDelete) => {
    /**
     * Delete's a goal
     */
    const { myGoals } = this.props.solutionMySolutions;
    const { insightData } = this.props.solutionGoals;
    const insightDataToDelete = myGoals[goalToDelete.index];

    mixpanel.track(TAXONOMIES.MYSOLUTIONS_DELETE_GOAL, {
      pointImprovementChoice: Number(insightDataToDelete.pointImprovement).toFixed(1),
      kpiChoice: insightDataToDelete.kpiPayload[0].name,
      targetGroupChoice: insightDataToDelete.targetGroups?.[0]?.name,
      timeFrameChoice: TIME_MAP[Number(insightDataToDelete.duration.split(' ')[0])]
    })

    const obj = {
      goal: insightDataToDelete.id,
      survey: insightData.surveyId,
    };

    this.props.doSolutionGoalDeleteV2(obj);

    this.setState({
      deleteModal: false,
      goalToBeDeleted: null,
      selectedGoalIndex: 0,
    });

    this.ref.current?.onSlideTo(0);
  };

  activateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionActivateV2({
      solution: row?.id,
      id: insightData?.surveyId,
      fields: { is_active: true },
      page: PAGE_NAME,
      solutionObj: row?.solution
    });
  };

  deactivateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionDeactivateV2({
      solution: row?.id,
      id: insightData?.surveyId,
      fields: { is_active: false },
      page: PAGE_NAME,
      solutionObj: row?.solution
    });
  };

  archiveProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionArchiveV2({
      solution: row.id,
      id: insightData.surveyId,
      fields: { is_archived: true },
      page: PAGE_NAME,
      solutionObj: row?.solution
    });
  };

  cleanGoals = () => {
    const { myGoals } = this.props.solutionMySolutions;
    let goals = [];
    for (const i in myGoals) {
      const kpi = myGoals[i].kpiPayload[0]?.name;
      const tg = myGoals[i].targetGroups[0]?.displayName;

      const ogScore = parseFloat(myGoals[i].originalScore);
      const newScore =
        parseFloat(myGoals[i].pointImprovement) + parseFloat(ogScore);
      const obj = {
        id: myGoals[i].id,
        deadline: myGoals[i].endDate,
        //TODO: UPDATE
        isAchieved: false,
        kpi: myGoals[i].kpiPayload[0]?.code || [],
        duration: myGoals[i].duration,
        title: `Improve ${kpiMap[kpi]} for ${tg}, bringing the score from ${ogScore} to ${newScore}`,
      };
      goals.push(obj);
    }

    return goals;
  };

  removeSavedTool = (companyTool) => {
    if (companyTool?.id) {
      const obj = {
        toolId: companyTool.tool.id,
        companyToolId: companyTool.id,
        tool_obj: companyTool.tool,
        page: PAGE_NAME,
      };
      this.props.doSolutionRemoveSavedToolV2(obj);
    }
  };

  handleSlideChange = (forward) => {
    const { selectedGoalIndex } = this.state;

    let newIndex = selectedGoalIndex;

    // We don't have to check for going below 0 or the max array length
    // due to the component controlling this function has built in checks
    // to not allow to go over/under the min and max values of the
    // array length
    if (forward) {
      // Go forward in the slide index
      newIndex = newIndex + 1;
    } else {
      // Go backward in the slide index
      newIndex = newIndex - 1;
    }

    this.setState({
      selectedGoalIndex: newIndex,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleSeeDetails = (rowSelected) => {
    const row = _.cloneDeep(rowSelected)
    mixpanel.track(TAXONOMIES.MYSOLUTIONS_SEE_PROGRAM_DETAILS, mapProgramToTracking(row.solution))
    
    let tools = [];
    let labels = [];

    for (const i in row.solution.tools) {
      let withBadge = true;
      if (row.solution.tools[i].type === "resource") {
        withBadge = false;
      }
      const cleanTool = {
        caption: row.solution.tools[i].title,
        href: row.solution.tools[i].url,
        icon: mapIcons(row.solution.tools[i]),
        title: row.solution.tools[i].mediaType,
        withBadge: withBadge,
      };
      tools.push(cleanTool);
    }
    for (const i in row.solution.targetGroups) {
      const obj = {
        icon: <PeopleIcon type="line" />,
        label: row.solution.targetGroups[i].name,
      };
      labels.push(obj);
    }

    const companySolution = row;
    row.solution.company_solution = companySolution;
    row.solution.isActive = row.isActive;
    row.solution.tools = tools;
    row.solution.labels = labels;
    row.solution.kpiCodes = getCleanedKpiCodes(row.solution.painPoints);
    row.solution.isSaved = true;
    this.setState({
      modalOpen: true,
      program: row.solution,
    });
  };

  removeCompanySolution = (row, isActive) => {
    this.props.doSolutionRemove({
      id: row.company_solution.id,
      program: row.id,
      program_obj: row,
      page: PAGE_NAME,
      isActive: isActive
    });
    this.setState({
      modalOpen: false,
    });
  };

  removeCompanySolutionRow = (row) => {
    this.props.doMySolutionRemoveV2({
      id: row.id,
      program: row.solution.id,
      program_obj: row,
      page: PAGE_NAME,
    });
  };

  render() {
    const {
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      modalOpen,
    } = this.state;

    const { myGoals, myPrograms, myTools, isLoading } = this.props.solutionMySolutions;
    const {
      selectedGoalIndex,
      showCreateGoalCard,
      deleteModal,
      goalToBeDeleted,
      program,
    } = this.state;
    const hasGoals = myGoals.length > 0;

    const cleanedGoals = hasGoals && this.cleanGoals();

    const companyName = getActiveCompany(this.props.userMetadata?.companies)?.name;

    const { insightData, options } =
      this.props.solutionGoals;

    const groupScores = insightData?.groupData;

    const renderCardData =
      insightData?.orgData &&
      groupScores &&
      selectedTargetGroup &&
      Object.keys(options).length != 0 &&
      selectedKPI;

    const targetGroupScore = () => {
      let score = groupScores[selectedTargetGroup][selectedKPI];

      if (NO_DATA.includes(score)) return null;

      return score;
    };

    const filteredKpis = () => {
      if (!selectedKPI) return null;

      // If not a custom goal then we want to remove the ability to change kpi
      let kpiOptions = options.kpis;
      const filteredKpi = [];
      for (const i in kpiOptions) {
        if (kpiOptions[i] === selectedKPI) {
          filteredKpi.push(kpiOptions[i]);
        }
      }
      return filteredKpi;
    };

    const filteredTargetGroups = () => {
      if (!selectedTargetGroup) return null;

      // If not a custom goal then we want to remove the ability to change target groups
      let targetGroupOptions = options.groups;
      const filteredGroup = [];
      for (const i in targetGroupOptions) {
        if (targetGroupOptions[i] === selectedTargetGroup) {
          filteredGroup.push(targetGroupOptions[i]);
        }
      }
      return filteredGroup;
    };

    const calculateScoreUpTo = () => {
      const { selectedImprovementScore } = this.state;
      let score = renderCardData && targetGroupScore();

      if (NO_DATA.includes(score)) return null;

      score = score + selectedImprovementScore;
      return score;
    };

    const filteredImprovementRange = () => {
      const range =
        renderCardData &&
        options?.pointsImprovementRange.filter(
          (value) => value + targetGroupScore() <= 10
        );

      if (range) return range;
      else return options.pointsImprovementRange;
    };

    // Create structure of object to be sent to the goal card component
    const goalCardData = {
      company: companyName,

      pointImprovement: selectedImprovementScore,
      pointImprovementOptions: filteredImprovementRange(),
      // We don't need the mapping here because selectedKPI = a kpi code, while in Goals.jsx selectedKPI = _average
      kpi: selectedKPI,
      kpiOptions: filteredKpis(),

      targetGroup: selectedTargetGroup,
      targetGroupOptions: filteredTargetGroups(),

      timeline: selectedTimeline,
      timelineOptions: options.timelineRange,

      scoreUpTo: calculateScoreUpTo(),
      onChange: (key, value) => this.handleParent(key, value),
      onClick: () => this.handleGoalCardUpdate(),
      type: "update",
      onClose: () =>
        this.setState({
          showCreateGoalCard: false,
          selectedKPI: null,
          selectedTargetGroup: null,
          selectedTimeline: null,
          selectedImprovementScore: null,
        }),
    };

    const toastError = this.props.message.toastError;

    const renderGoalsList = () => {
      if (hasGoals) {
        return (
          <ul className="your-goals-list">
            {
              myGoals.map((row, index) => {
                return (
                  <li className="your-goals-list-item" key={index}>
                    <YourGoalsCard
                      type="increase"
                      deadline={row.endDate}
                      selected={index === selectedGoalIndex}
                      score={parseFloat(row.pointImprovement)}
                      kpi={
                        row.kpiPayload.length && row.kpiPayload[0].code
                      }
                      targetGroup={
                        row.targetGroups.length &&
                        row?.targetGroups[0].displayName
                      }
                      onClick={() => this.handleSelectedGoal(index)}
                    />
                  </li>
                );
              })
            }
          </ul>
        )
      }

      return null
    }

    const renderGoalsCarousel = () => {
      if (showCreateGoalCard) {
        return (
          <div style={{ width: "100%" }}>
            <GoalCard {...goalCardData} />
          </div>
        );
      }
      
      if (deleteModal) {
        return (
          <div style={{ width: "100%" }}>
            <div className="delete-check">
              <div className="delete-text">
                <h3>Delete this goal?</h3>
                <p>This action cannot be undone.</p>
              </div>
              <div className="delete-actions">
                <div>
                  <Button
                    onClick={() =>
                      this.setState({
                        deleteModal: false,
                        goalToBeDeleted: null,
                      })
                    }
                  >
                    Keep Goal
                  </Button>
                  <Button style={{ backgroundColor: "red" }} onClick={() => this.handleGoalDelete(goalToBeDeleted)}>
                    Delete Goal
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <GoalsCarousel
          goals={cleanedGoals}
          menuItemActions={{
            onDelete: (goal) =>
              this.setState({
                deleteModal: true,
                goalToBeDeleted: goal,
              }),
            // onUpdate: (goal) => this.handleOpenUpdateGoal(goal),
            onUpdate: (goal) => console.log("to be removed"),
          }}
          onClickPrev={() => this.handleSlideChange(false)}
          onClickNext={() => this.handleSlideChange(true)}
          // onUpdate={(obj) => console.log(obj)}
          ref={this.ref}
        />
      );
    };

    return (
      <div
        className={
          modalOpen ? "your-goals-container blur" : "your-goals-container"
        }
      >
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError?.type}
              message={toastError?.message}
              onClick={() => this.props.resetGlobalToast()}
            />
          </div>
        )}

        <div className="your-goals-content">
          <div className="your-goals-wrapper">
            <div className="your-goals-header">
              <h2>Your Goals</h2>

              {myGoals?.length ? <p>{`(${myGoals?.length})`}</p> : null}
            </div>

            <div className="your-goals-body">
              {isLoading.myGoals ? 
                <>
                  <ul className="your-goals-list">
                    <YourGoalsListSkeleton numberOfElements={4} />
                  </ul>
                  <GoalsCarouselSkeleton />
                </> : 
                (myGoals?.length ?
                  <>
                    {renderGoalsList()}
                    <div className={classNames("your-goal-card", { "no-goals": !hasGoals })}>
                      {renderGoalsCarousel()}
                    </div>
                  </> : 
                  <BannerCard
                    title="Stay accountable when you set goals"
                    subtitle="Create insight-based goals to receive program recommendations unique to your company’s pain points."
                    component={Link}
                    to={solutionUrls.solution_goals}
                  />
                )
              }
            </div>
            <div className="your-programs">
              <div className="your-saved-programs">
                <div className="your-saved-header">
                  <h2>Saved Programs</h2>

                  <SaveIcon type="bold" />

                  <p>{`(${myPrograms?.non_active?.length || 0})`}</p>
                </div>

                <div className="saved-programs">
                  {/* <div className="saved-filter-header">
                  <p>Sort By</p>
                  <div className="saved-filter-header-dropdowns">
                    <Select
                      options={programsFilters.length ? programsFilters : []}
                      placeholder="Target Group"
                      type="block"
                      variant="light"
                      icon={<PeopleIcon type="line" />}
                    />
                    <Select
                      options={programsFilters.length ? programsFilters : []}
                      placeholder="Pain Points"
                      type="block"
                      variant="light"
                      icon={<PainPointIcon type="line" />}
                    />
                  </div>
                </div> */}
                  <div className="saved-programs-list">
                    {
                      isLoading.myPrograms || myPrograms?.non_active === undefined
                        ? <SavedProgramListSkeleton numberOfElements={4} />
                        : myPrograms?.non_active.map((row, index) => {
                            return (
                              <SavedProgram
                                description={row.description}
                                kpis={getCleanedKpiCodes(row.solution.painPoints)}
                                menuItemActions={{
                                  onAddToActive: () => this.activateProgram(row),
                                  onArchive: () => this.archiveProgram(row),
                                  onRemoveFromSaved: () =>
                                    this.removeCompanySolutionRow(row),
                                }}
                                numberOfGroups={row.solution.kpis?.length}
                                numberOfTools={row.solution.tools?.length}
                                programName={row.solution.title}
                                key={index}
                                onClick={() => this.handleSeeDetails(row)}
                              />
                            );
                          })
                    }
                  </div>
                </div>
              </div>

              <div className="your-active-programs">
                <div className="your-active-header">
                  <h2>Active Programs</h2>

                  <CheckmarkIcon type="bold" />

                  <p>{`(${myPrograms?.active?.length || 0})`}</p>
                </div>

                <div className="active-programs-wrapper">
                  <div className="active-programs-list">
                    {
                      isLoading.myPrograms ||  myPrograms?.active === undefined
                        ? <ActiveProgramListSkeleton numberOfElements={4} />
                        : myPrograms?.active.map((row, index) => {
                            return (
                              <ActiveProgram
                                kpis={getCleanedKpiCodes(row.solution.painPoints)}
                                menuItemActions={{
                                  onTransferToSaved: () =>
                                    this.deactivateProgram(row),
                                  onArchive: () => this.archiveProgram(row),
                                }}
                                onClick={() => this.handleSeeDetails(row)}
                                numberOfGroups={row.solution?.kpi_payload?.length}
                                numberOfTools={row.solution?.tools?.length}
                                programName={row.solution?.title}
                                key={index}
                              />
                            );
                          })
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="your-tool-header">
              <hr class="rounded" />
              <h2>Your Tool Box</h2>
              <h3>This is where your favourite tools live</h3>
            </div>

            <div className="your-tool-list">
              {
                isLoading.myTools
                  ? <ToolCardListSkeleton size="small" numberOfElements={4} listElementWrapperClassName="tool-card-wrapper my-solutions-tool" />
                  : <>
                      {
                        myTools?.map((row, index) => {
                          const cleanedTitle = row.tool.title
                            ? row.tool.title
                              .replace(".pdf", "")
                              .replace(".docx", "")
                            : null
                          return (
                            <div className="tool-card-wrapper my-solutions-tool">
                              <ToolCard
                                key={index}
                                category={
                                  toTitleCase(row?.tool.mediaType)
                                }
                                kpis={getCleanedKpiCodes(row.tool.painPoints)}
                                targetGroups={getCleanedGroups(row.tool.targetGroups)}
                                publicationYear={row.tool.created}
                                title={
                                  cleanedTitle
                                }
                                description={row.tool.description}
                                isSaved={row.tool.isSaved}
                                href={row.tool.url}
                                onRemove={() => this.removeSavedTool(row)}
                                onClick={(e) => handleClickTool(e, row, PAGE_NAME)}
                                onProvideFeedback={() => window.location.href = `mailto:cs@diversio.com?subject=Diversio Tools Feedback - ${cleanedTitle}`}
                                size="small"
                              />
                            </div>
                          );
                        })
                      }
                    
                      <MyReports />
                    </>
              }
            </div>

            <div className="your-archived-wrapper">
              {/* <div className="archived-add-solution">
              <div className="archived-add-solution-wrapper">
                <h3>Have a solution to add to your dashboard?</h3>
                <Button color="secondary" leftIcon={<PlusIcon type="line" />}>
                  Add Program
                </Button>
              </div>
            </div> */}

              <div className="archived-total">
                <div className="archived-card-text">
                  <h2>Archived</h2>

                  <h3>{myPrograms.archived?.length} Total</h3>
                </div>

                <div className="archived-card-action">
                  <div className="archived-card-action-text">
                    <p>
                      Declutter your dashboard by archiving your Active
                      Solutions
                    </p>
                  </div>

                  <div className="archived-card-action-btn">
                    <Button
                      rightIcon={<ChevronRightIcon type="line" />}
                      onClick={() =>
                        this.props.history.push("/my-solutions/archive")
                      }
                    >
                      See All
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={() => this.handleModalClose(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={modalOpen}>
            <Box className="my-solutions-modal-container">
              <ProgramCell
                defaultExpanded={true}
                programName={program?.title}
                description={program?.description}
                kpis={program?.kpiCodes}
                onAddToActive={() => {
                  const programCopy = { ...program };
                  programCopy.is_active = true;
                  this.activateProgram(program.company_solution);
                  this.setState({
                    program: programCopy,
                  });
                }}
                onRemoveFromActive={() => {
                  const programCopy = { ...program };
                  programCopy.is_active = false;
                  this.deactivateProgram(program.company_solution);
                  this.setState({
                    program: programCopy,
                  });
                }}
                onRemoveFromSaved={() =>
                  this.removeCompanySolution(program, program?.is_active)
                }
                programLabels={program?.labels}
                tools={program?.tools}
                isSaved={program?.isSaved}
                isAdded={program?.isActive}
              />
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(MySolutions));
