import React, {useEffect} from 'react';
import classNames from "classnames";
import './index.scss';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/HelpOutline";

const Rating = ({ question, company, handleChange }) => {
    const { title, required, type, placeholder, choices } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    const _handleTitleChange = (val) => {
        handleChange({
            ...question,
            title: val
        })
    }

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);
    return (
        <div className={classNames("question", "rating-question", { "required": required })}>
            <div className={"question-title"}>
                <div className={'question-title-container'}>
                    <span onBlur={e => _handleTitleChange(e.target.textContent)}>{question.qnum}. {parsedTitle}</span>
                    <span className={"asterisk"}>*</span>
                </div>
                <div className={"question-title-description-container"}>
                    {
                        question.description
                            ? <Tooltip className="description-tooltip" title={question.description} >
                                <HelpIcon />
                            </Tooltip>: null
                    }
                </div>
            </div>
            <div className={"rating-question__rate-container"}>
                <p>No, not at all</p>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <p>Yes, definitely</p>
            </div>
        </div>
    );
};

export default Rating;
