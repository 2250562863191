import styled from 'styled-components'
import { Button as DdsButton, getFontStyle, makeLineClamp } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

import { ChipsListItem as DefaultChipsListItem } from './../Training/training.styles'

export const SidePanelWrapper = styled.div`
    height: 100%;
    width: 544px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
    position: relative;

    .dds-button {
        overflow: hidden;
    }
`

export const Container = styled.div`
    height: calc(100% - 88px);
    overflow: auto;
    padding: 32px;

    ${media.lg} {
        padding: 48px;
    }
`

export const CloseButton = styled(DdsButton)`
    &.dds-button {
        position: absolute;
        left: -48px;
        top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.primaryWhite};
        box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
    }
`

export const Image = styled.div`
    height: 247px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary20};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const Title = styled.h2`
    margin: 20px 0 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const Subtitle = styled.p`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p01Regular')}
`

export const ChipsList = styled.ul`
    margin: 0 0 32px;
    padding: 0;
    list-style-type: none;
`

export const ChipsListItem = styled(DefaultChipsListItem)`
    padding: 12px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray1};

    .dds-chip__label {
        word-break: break-all;

        ${makeLineClamp(1)};
    }
`

export const ChipDescription = styled.span`
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegularM')}
`

export const Objectives = styled.div`
    margin-bottom: 20px;
`

export const ObjectivesTitle = styled.h3`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('cardHeaderBold')}
`

export const Buttons = styled.div`
    display: flex;
    margin-top: 24px;
    gap: 12px;
    padding: 32px;
    padding-top: 0;

    ${media.lg} {
        padding: 48px;
        padding-top: 0;
    }

    .dds-button {
        width: 50%;
    }
`
