import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon, colors } from '@diversioteam/diversio-ds'

import { useBreadcrumbs } from 'hooks/useBreadcrumbs'

import * as S from './analyzeBreadcrumbs.styles'

export const AnalyzeBreadcrumbs = () => {
    const { links } = useBreadcrumbs()

    return (
        <S.AnalyzeBreadcrumbsWrapper>
            {
                <S.Breadcrumbs
                    separator={<ChevronRightIcon type="line" height={8.75} color={colors.secondary300} />}
                    aria-label="breadcrumb"
                >
                    {links.map((path, id) => {
                        return (
                            <Link to={path.to} color="textPrimary" key={id}>
                                {path.label}
                            </Link>
                        )
                    })}
                </S.Breadcrumbs>
            }
        </S.AnalyzeBreadcrumbsWrapper>
    )
}
