import React, { useMemo, useRef, useState } from 'react'
import { IntersectionalityFilterRef } from '@diversioteam/diversio-ds'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { parseYearQuarter } from 'utils'

import { FiltersContextController } from 'context/filters/filtersContextController/FiltersContextController'
import { useViewTracking } from 'hooks/useViewTracking'
import { useVersion } from 'hooks/useVersion'
import { useUserInformation } from 'hooks/useUserInformation'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

import { NoInsights } from './../NoInsights'
import { Data } from './Data'
import { FilterPane } from './FilterPane'
import { InclusionParams } from './inclusion.types'
import * as S from './inclusion.styles'

export const Inclusion = () => {
    useViewTracking('Inclusion V2')

    const { tab } = useParams<InclusionParams>()
    const defaultTabType = tab?.includes('-') ? 'bespoke' : ''

    const versionsContext = useVersion()
    const { enableDebugMode } = useUserInformation()

    const [selectedTab, setSelectedTab] = useState<InclusionParams['tab']>(tab || 'target_groups')
    const [selectedTabType, setSelectedTabType] = useState<string>(defaultTabType)
    const history = useHistory()
    const ref = useRef<IntersectionalityFilterRef | null>(null)

    const { survey } = useSurveys()

    const params = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyFilters = useGetCompanyFilters(params)

    const handleOnTabChange = (to: InclusionParams['tab'], tabType: string) => {
        setSelectedTab(to)
        setSelectedTabType(tabType)

        if (selectedTab !== to) {
            return history.replace(`/analyze2/inclusion/${to}${location.search}`)
        }
    }

    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2

    const { hasBespokeInclusionQuestions, hasEngagementInclusionQuestions, hasInclusionQuestions } =
        queryGetCompanyFilters.data || {}

    const hasNoData = !hasBespokeInclusionQuestions && !hasEngagementInclusionQuestions && !hasInclusionQuestions

    if (!isV2Enabled && !enableDebugMode) {
        return <Redirect to={generateCompanyURI('/analyze/inclusion')} />
    }

    if (queryGetCompanyFilters.isFetched && hasNoData) {
        return (
            <NoInsights
                details={[
                    {
                        title: 'Interested in collecting Inclusion data?',
                        description: (
                            <>
                                Contact your CSM or send a message to{' '}
                                <a href="mailto:client-success@diversio.com">client-success@diversio.com</a>
                            </>
                        ),
                    },
                    {
                        title: 'Why don’t I see Inclusion results?',
                        description:
                            'Double check the time period at the top left of screen to ensure diversity data was collected.',
                    },
                    {
                        title: 'What insights does Inclusion data provide?',
                        description:
                            'Employee sentiment breakdown along Dominant group vs Non-Dominant groups, departments, offices, or any group you’re interested in. Compare your scores to industry benchmarks.',
                    },
                ]}
            />
        )
    }

    return (
        <FiltersContextController>
            <S.Inclusion>
                <S.Content>
                    <S.LeftBox>
                        <FilterPane
                            handleOnTabChange={handleOnTabChange}
                            selectedTab={selectedTab}
                            selectedTabType={selectedTabType}
                            ref={ref}
                        />
                    </S.LeftBox>

                    <S.RightBox>
                        <Data selectedTab={selectedTab} selectedTabType={selectedTabType} ref={ref} />
                    </S.RightBox>
                </S.Content>
            </S.Inclusion>
        </FiltersContextController>
    )
}
