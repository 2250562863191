import { diversioApiV2 } from 'api/axios/axiosInstance'

import { GetNpsResponse } from './npsActions.types'
import { endpoints } from './../endpoints'

export const getNps = async (): Promise<GetNpsResponse> => {
    const response = await diversioApiV2.get(endpoints.nps)

    return response.data
}
