import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import classNames from "classnames";
import _ from 'underscore';
import { UnstableChip as Chip } from '@diversioteam/diversio-ds'
import Filters, {Year, Office, Level, Department, Tenure, FiltersSelected, FilterContext} from '../../Analyze/Filters';
import "./index.scss";
import {industryOrRegional, mapChoiceLabel} from "../../../utils";
import { _makeCell } from "../../Home/HeatMap/utils";
import {
    getOfficeRegionLabel,
    getTenureLabel,
    mapMetaKpiLabels,
    getDepartmentLabel,
    getDominantGroupCellLabel,
    getYearLabel
} from "../../../utils/labels";
import { withTranslation } from 'react-i18next';
import MainCard from "../../utils/MainCard";
import Legend from './Legend';
import ReactTooltip from 'react-tooltip';
import * as m from '../../../translations/mapping';
import { mapT } from '../../../translations/utils';
import {createFiltersPayload, getYearAliasMap} from "../../Analyze/Filters/utils";
import i18n from "../../../i18n";
import HeatmapSnapshot from "./Snapshot";
import {loadCompanyFilters} from "../../../sagas/network";
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import { INSUFFICIENT_DATA, NOT_AVAILABLE } from 'components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils';


/* Table columns in desired order of appearance */
const COLUMN_KEYS = [
    'INCLUSIVE_CULTURE',
    'FAIR_MANAGEMENT',
    'CAREER_DEVELOPMENT',
    'WORKPLACE_FLEXIBILITY',
    'WORKPLACE_SAFETY',
    'RECRUITING_HIRING'
];

class HeatMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: 'normal',
            row: "-1",
            column: '-1',
            selectedFilters: {},
            tab: "overview",
            currentPage: 1,
            dominantGroups: props.allFilters.dominant_group
        };
    };

    componentDidMount() {
        this._resetData();
    }

    setCurrentPage = (page) => {
        this.setState({
            currentPage: page || 1,
        })
    }

    onHover = (r, c) => {
        this.setState({
            isHover: 'hover',
            row: r,
            column: c
        })
    };

    offHover = () => {
        this.setState({
            isHover: 'hoverEnter',
            row: "-1",
            column: "-1"
        })
    };

    /**
     * Loads heatmap data upon render or using "Reset Filter" button.
     * Sets current heatmap back to the first tab.
     */
    _resetData () {
        if (this.props.currentHeatmap == "heatmap1") {
            if (this.props.demQuestions?.[0]) {
                this.props.getNonOverviewMetricsData({
                    data: { 
                        key: "bespoke", 
                        bespoke_question_id: this.props.demQuestions[0].id,
                        kpi_header_type: "classic",
                    }
                });
                this.setState({
                    ...this.state,
                    tab: this.props.demQuestions[0].title
                });
                
            }
        }
        if (this.props.currentHeatmap == "heatmap2") {
            if (this.props.expQuestions?.[0]) {
                this.props.getNonOverviewMetricsData({
                    data: { 
                        key: "bespoke", 
                        bespoke_question_id: this.props.demQuestions[0].id,
                        kpi_header_type: "bespoke_experience",
                    }
                });
                this.setState({
                    ...this.state,
                    tab: this.props.demQuestions[0].title
                });
            }
        }
        if (this.props.currentHeatmap == "heatmap3") {
            this.props.getInclusionMetricsDataBespoke({data: {year: this.props.latestSurveyYear, level: "", office: "", department: "", kpi_header_type: "bespoke_experience"}});
            this.setState({
                ...this.state,
                tab: "overview",
            });
            loadCompanyFilters(`year=${this.props.latestSurveyYear}`)
                .then(res => setTimeout(() => this.props.doUpdateCompanyFilters && this.props.doUpdateCompanyFilters(res.data), 0));
        }
    }


    /**
     * Sets the heatmap to the selected tab and loads the appopriate data.
     * 
     * @param {string} tab - The string name of the selected tab
     * @param {number} id - The id of the bespoke question associated with the current tab
     * @param {string} heatmap - The string name of the current heatmap
     */
    selectTab = (tab, id, heatmap) => {
        this.setState({
            ...this.state,
            tab
        });

        // reset the currentPage in Snapshot to 1,
        // in case user has scrolled using the navigation arrows
        this.setCurrentPage(1);

        if (heatmap == "heatmap1") {
            this.props.getNonOverviewMetricsData({
                data: { 
                    key: "bespoke", 
                    bespoke_question_id: id,
                    kpi_header_type: "classic",
                }
            });
        }
        if (heatmap == "heatmap2") {
            this.props.getNonOverviewMetricsData({
                data: { 
                    key: "bespoke", 
                    bespoke_question_id: id,
                    kpi_header_type: "bespoke_experience"                }
            });
        }
        if (heatmap == "heatmap3") {
            if (tab === "overview") {
                this._resetData();
            } else {
                // Table column headings may change depending on the year selected.
                // When a non-overview tab is selected, we want to refetch the bespoke questions for the latest year/quarter
                loadCompanyFilters(`year=${this.props.latestSurveyYear}`)
                .then(res => setTimeout(() => this.props.doUpdateCompanyFilters && this.props.doUpdateCompanyFilters(res.data), 0));

                this.props.getNonOverviewMetricsData({ data: { key: tab, kpi_header_type: "bespoke_experience"} });
            }
        }
    };

    /**
     * Refreshes data with the selected filters applied
     * @param data - The filters that have been selected
     */
    handleApplyFilters = (data, filters) => {
        this.setState({
            dominantGroups: filters.dominant_group
        })
        if (this.state.tab === "overview") {
            this.props.getInclusionMetricsDataBespoke({data: {...data, kpi_header_type: "bespoke_experience"}});
        } else {
            this.props.getNonOverviewMetricsData({ data: { ...data, key: this.state.tab, kpi_header_type: "bespoke_experience"} });
        }
    };

    handleClearFilters = () => {
        this._resetData();
    }

    hoverEnter = (row, column) => {
        this.onHover(row, column);
    };

    /**
     * @returns the id of the bespoke question associated with the current tab
     */
    getCurrentTabId = () => {
        const tabName = this.state.tab;
        const id = this.props.bespokeQuestions.filter(question => 
            question.title == tabName
        )[0].id
        return id
    }

    _handleExcelExport = () => {
        let baseData = { ...this.state.selectedFilters, export_format: "excel", lang: i18n.language }

        if (this.props.currentHeatmap == "heatmap1") {
            const currentTabId = this.getCurrentTabId()
            this.props.getNonOverviewMetricsExcel({ data: { ...baseData, key: "bespoke", kpi_header_type: "classic", bespoke_question_id: currentTabId, } });
        }
        if (this.props.currentHeatmap == "heatmap2") {
            const currentTabId = this.getCurrentTabId()
            this.props.getNonOverviewMetricsExcel({ data: { ...baseData, key: "bespoke", kpi_header_type: "bespoke_experience", bespoke_question_id: currentTabId } });
        }
        if (this.props.currentHeatmap == "heatmap3") {
            if (this.state.tab === "overview") {
                this.props.getInclusionMetricsExcel({ data: {...createFiltersPayload(this.state.selectedFilters, this.props.allFilters), export_format: "excel", lang: i18n.language, kpi_header_type: "bespoke_experience"} });
            } else {
                this.props.getNonOverviewMetricsExcel({ data: { export_format: "excel", lang: i18n.language, key: this.state.tab, kpi_header_type: "bespoke_experience" } });
            }
        }
    };

    /**
     * @param {*} expQuestions - An array of question objects
     * @returns {string[]} an array containing the bespoke experience questions
     */
    getExpQuestionsList = (expQuestions) => {
        let expQuestionTitles = []
        for (const question of expQuestions) {
            expQuestionTitles.push(question.title_alias || question.title)
        }
        return expQuestionTitles
    }

    getDgTooltipContent = (companyName) => {
        const dgItems = this.state.dominantGroups.available_descriptors
        const hasPartial = dgItems && dgItems.some((item) => item.includes("partial"))
        const dominantGroupLabel = getDominantGroupCellLabel(companyName, "Dominant Group");
        let content = `${dominantGroupLabel} refers to the combined demographics most represented in senior levels at your organization` + (hasPartial ? ('; subject to the country’s data restriction policies.\n\nAny group denoted "partial" has data unavailable for at least 1 survey respondent.') : '.');
        if (dominantGroupLabel === "Majority Group"){
           content = "Majority group represented in senior executive levels";
        }

        return (
            <>  
                <div className='datatable__dg-tooltip-labels'>
                    {dgItems && dgItems.map(field =>
                        <Chip label={field} color="light" key={field} />
                    )}
                </div>
                
                <div className='datatable__dg-tooltip-description'>
                    {content}
                </div>

                <div className='datatable__dg-tooltip-description'>
                    {this.state.dominantGroups.help_text}
                </div>
            </>
        )
    }

    render() {
        const { metadata = [], companyName, t, data, prevData, overall, previousOverall, loadingNonOverviewMetrics, demQuestions, expQuestions, currentHeatmap, warningMessage, bespokePage, latestSurveyYear} = this.props;
        const { tab } = this.state;
        const disableMyKpi = (tab !== "overview");
        const heatmapText = {
            "heatmap1": {
                "title": t("BESPOKE.CARD_HEADERS.HEATMAP1.title"),
                "tooltip":t("BESPOKE.CARD_HEADERS.HEATMAP1.tooltip"),
            },
            "heatmap2": {
                "title": t("BESPOKE.CARD_HEADERS.HEATMAP2.title"),
                "tooltip":t("BESPOKE.CARD_HEADERS.HEATMAP2.tooltip"),
            },
            "heatmap3": {
                "title": t("BESPOKE.CARD_HEADERS.HEATMAP3.title"),
                "tooltip":t("BESPOKE.CARD_HEADERS.HEATMAP3.tooltip"),
            },
        }

        // Only render table data if data needed for heatmap exists
        let renderTable = false
        if (currentHeatmap == "heatmap1") {
            // Check whether KPI scores contain numerical values rather than 'INSUFFICIENT_DATA' or 'N/A'
            let hasData = false
            const kpiOptions = ["CAREER_DEVELOPMENT", "WORKPLACE_FLEXIBILITY", "INCLUSIVE_CULTURE", "RECRUITING_HIRING", "FAIR_MANAGEMENT", "WORKPLACE_SAFETY"]
            for (const kpiData of data) {
                for (const [kpiName, kpiScore] of Object.entries(kpiData)) {
                    if (kpiOptions.includes(kpiName)) {
                        if (kpiScore !== INSUFFICIENT_DATA && kpiScore !== NOT_AVAILABLE) {
                            hasData = true
                            break
                        }
                    }
                }

            }
            renderTable = demQuestions?.length > 0 && data?.length > 0 && hasData
        }
        if (currentHeatmap == "heatmap2") {
            renderTable = expQuestions?.length > 0 && demQuestions?.length > 0 && data?.length > 0
        }
        if (currentHeatmap == "heatmap3") {
            renderTable = expQuestions?.length > 0 && data?.length > 0
        }

        return (

            <MainCard title={heatmapText[currentHeatmap]["title"]}
                      toolTipContent={heatmapText[currentHeatmap]["tooltip"]}
                      subheader={warningMessage()}>
            <div id={"bespoke-heat-map"} className={"bootstrap-override"}>
                <Filters
                    hidden={tab !== "overview"}
                    filters={this.props.allFilters}
                    applyFilters={this.handleApplyFilters.bind(this)}
                    clearFilters={this.handleClearFilters.bind(this)}
                    companyName={companyName}
                    doUpdateCompanyFilters={this.props.doUpdateCompanyFilters}
                    yearAliasMap={getYearAliasMap(companyName)}
                    bespokePage={bespokePage}
                    currentHeatmap={currentHeatmap}
                    latestSurveyYear={latestSurveyYear}
                >
                    <Year label={getYearLabel(companyName)}/>
                    <Office label={getOfficeRegionLabel(companyName)}/>
                    <Level label={t("ANALYZE.FILTERS.LEVEL")}/>
                    <Department label={getDepartmentLabel(companyName)}/>
                    <Tenure label={getTenureLabel(companyName)}/>
                    {
                        <FilterContext.Consumer>
                            {data => {
                                if (this.state.selectedFilters !== data.values) {
                                    this.setState({
                                        ...this.state,
                                        selectedFilters: data.values
                                    })
                                }
                            }}
                        </FilterContext.Consumer>
                    }
                </Filters>
                {currentHeatmap == "heatmap1" && 
                    <HeatmapSnapshot 
                        title={tab}
                        companyName={companyName}
                        data={data}
                        prevData={prevData}
                        overall={overall}
                        previousOverall={previousOverall}
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        loadingNonOverviewMetrics={loadingNonOverviewMetrics}
                    />
                }

                <Button className={"export-excel-btn"} onClick={this._handleExcelExport}>
                    Download Excel
                </Button>
                {(currentHeatmap == "heatmap2" || (currentHeatmap == "heatmap3" && tab != "overview")) ?
                    <hr style={{borderColor: "white", marginTop: "0"}}/> :
                    <hr/>
                }
                <div className='datatable-container'>
                    <Table className={"datatable"}>
                        <thead className="datatable__scrollable-table-head">
                            
                                { /* CLICKABLE TABS */}
                                {currentHeatmap != "heatmap3" ? 
                                <tr className={"datatable-filters"}>
                                    {demQuestions && demQuestions.map((question, index) =>
                                        <th 
                                            onClick={() => {
                                                this.selectTab(question.title, question.id, currentHeatmap)
                                            }} 
                                            className={classNames("tab bespoke", {"selected":  tab === question.title})}>
                                                {`${question.title.substring(0, 20).trim()}${question.title.length > 20 ? '...' : ''}`}
                                        </th>
                                    )}
                                    <th className={"empty-cell"}></th>
                                </tr>
                                :
                                <tr className={"datatable-filters"}>
                                    <th onClick={() => {
                                        this.selectTab("overview", 0, currentHeatmap)
                                    }}
                                    className={classNames("tab bespoke", {"selected": tab === "overview"})}>{t("ANALYZE.FILTERS.OVERVIEW")}</th>
                                    <th onClick={() => {
                                        this.selectTab("department", 0, currentHeatmap)
                                    }} className={classNames("tab bespoke", {"selected":  tab === "department"})}>{getDepartmentLabel(companyName)}</th>
                                    <th onClick={() => {
                                        this.selectTab("office", 0, currentHeatmap)
                                    }} className={classNames("tab bespoke", {"selected":  tab === "office"})}>{getOfficeRegionLabel(companyName)}</th>
                                    <th onClick={() => {
                                        this.selectTab("level", 0, currentHeatmap)
                                    }} className={classNames("tab bespoke", {"selected":  tab === "level"})}>{t("ANALYZE.FILTERS.LEVEL")}</th>
                                    <th onClick={() => {
                                        this.selectTab("year", 0, currentHeatmap)
                                    }} className={classNames("tab bespoke", {"selected":  tab === "year"})}>{t("ANALYZE.FILTERS.YEAR")}</th>
                                    <th onClick={() => {
                                        this.selectTab("tenure", 0, currentHeatmap)
                                    }} className={classNames("tab bespoke", {"selected":  tab === "tenure"})}>{t("ANALYZE.FILTERS.TENURE")}</th>
                                    <th className={"empty-cell"}></th>
                                </tr>
                                }
                                
                            <tr className={"datatable-border"}></tr>
                            <tr className={"datatable-metric"}>
                            <th>{renderTable ? null : "No data to display."}</th>
                            
                                { /* COLUMN HEADINGS */}
                                {renderTable &&
                                    ((currentHeatmap == "heatmap1" ?
                                    metadata.map((meta, id) => (
                                        <th key={id}>
                                            <a data-tip data-for={`tooltip-heatmap1-${id}`} data-event='click focus' className="dashboard-tooltip__clickable">
                                                <ReactTooltip
                                                    isCapture={true}
                                                    id={`tooltip-heatmap1-${id}`}
                                                    globalEventOff='click'
                                                    getContent={() => {
                                                        return (
                                                            <div className="dashboard-tooltip__content">
                                                                {`${mapT(m.METRIC_TO_TOOLTIP, meta.name, companyName)}`}
                                                            </div>
                                                        );}}
                                                    className="dashboard-tooltip-custom-class"
                                                    arrow
                                                    place="top"
                                                    textColor='#322352'
                                                    backgroundColor='#FFF'
                                                    effect='solid'
                                                />
                                                <span>{mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.name), companyName)} </span>
                                            </a>
                                        </th>)
                                    ) :
                                    expQuestions.map((meta, id) => (
                                        <th key={id}>
                                            <a data-tip data-for={`tooltip-${id}`} data-event='click focus' className="dashboard-tooltip__clickable">
                                                <ReactTooltip
                                                    isCapture={true}
                                                    id={`tooltip-${id}`}
                                                    globalEventOff='click'
                                                    getContent={() => {
                                                        return (
                                                            <div className="dashboard-tooltip__content">
                                                                {`${mapT(m.METRIC_TO_TOOLTIP, meta.title, companyName)}`}
                                                            </div>
                                                        );}}
                                                    className="dashboard-tooltip-custom-class"
                                                    arrow
                                                    place="top"
                                                    textColor='#322352'
                                                    backgroundColor='#FFF'
                                                    effect='solid'
                                                />
                                                <span>{mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.title), companyName)} </span>
                                            </a>
                                        </th>)
                                    )))
                                }
                            </tr>
                        </thead>


                        <tbody className="datatable__scrollable-table-body">

                        { /* ROW HEADINGS */}
                        { renderTable &&
                        (data.map((metric, id) => {
                            let title;
                            if (currentHeatmap == "heatmap3") {
                                if (metric.title === "Industry Average") {
                                    title = mapT(m.ANALYZE_HEATMAP_SIDEBAR,
                                mapChoiceLabel(industryOrRegional(metric.title,this.props.companyName)), companyName);
                                } else {
                                    title = mapT(m.ANALYZE_HEATMAP_SIDEBAR, metric.title);
                                }
                            } else {
                                title = metric.title
                            }
                            
                            const formatTitle = (title) => {
                                if (_.isEmpty(title)) { return null; }
                                return title.replace("/", " / ")
                             }

                            const getDominantGroupLabel = (title) => (
                                <div style={{display: "flex"}}>
                                    {formatTitle(getDominantGroupCellLabel(companyName, title))}
                                    <div className="icon-question-mark-wrapper" style={{display: "flex", alignItems: "center", textTransform: "none", marginLeft: "5px"}}>
                                        <DashboardTooltip content={this.getDgTooltipContent(companyName)}>
                                            <img className="icon icon-question-mark" style={{height: "15px", width: "15px"}}/>
                                        </DashboardTooltip>
                                    </div>
                                </div>
                        )

                            return (
                                <tr id="xLabel-cell" key={id}>
                                    <td>
                                        {
                                            title == mapT(m.ANALYZE_HEATMAP_SIDEBAR, "Dominant Group") ?
                                            getDominantGroupLabel(title) :
                                            formatTitle(title)
                                        }
                                    </td>
                                    
                                    { /* CELL DATA */}
                                    {currentHeatmap == "heatmap1" ?
                                        COLUMN_KEYS.map(key => (_makeCell(
                                            metric,
                                            prevData && prevData[id] ? prevData[id] : null,
                                            key,
                                            id,
                                            disableMyKpi
                                        )))
                                    : 
                                    this.getExpQuestionsList(expQuestions).map(key => (_makeCell(
                                            metric,
                                            prevData && prevData[id] ? prevData[id] : null,
                                            key,
                                            id,
                                            disableMyKpi
                                        )))
                                    }
                                </tr>
                            )
                        }))}
                        </tbody>
                    </Table>
                </div>
                <div>
                    <Legend currentHeatmap={currentHeatmap}/>
                    {
                        tab === "overview" && <FiltersSelected
                        values={this.state.selectedFilters}
                        allFilters={this.props.allFilters}
                            companyName={this.props.companyName}
                        />
                    }
                </div>
            </div>
            </MainCard>
        );
    }
};

export default withTranslation()(HeatMap);
