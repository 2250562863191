import React from 'react';
import {Container, Form} from "react-bootstrap";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import classNames from 'classnames';
import Progress from "./Progress";
import Joyride from 'react-joyride';
import HelpIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

const Setup = ({
    handleSetupNext,
    handleSetupBack,
    handleReset,
    isSaving,
    surveyErrors,
    selectedSurvey = {
        deploying_for: "EMPL",
        supported_languages: ["en"],
        survey_type: "DNI",
        download_responses: false,
        employee_count: 0
    },
    returningUser,
    selfServe
}) => {
    const [steps, setSteps] = React.useState([
        {
            target: ".survey-setup__content-container",
            content: "Please answer a few questions before creating a survey.",
            placement: "left",
            disableBeacon: true
        },
        {
            target: ".next-btn",
            content: "Click here to begin creating your survey",
            placement: "left",
        }
    ]);
    const [showJoyride, setShowJoyride] = React.useState(false);
    const selfServeOrReturningUser = returningUser || selfServe
    
    React.useEffect(() => {
        setTimeout(() => {
            if (localStorage.getItem("joyride__setup--completed") !== "1") {
                setShowJoyride(true);
            }
        }, 1000);

        return () => {
            setShowJoyride(false);
        }
    }, [])

    const setupSchema = yup.object().shape({
        deployingWith: yup.string().required("Please choose the type of survey you would like to create").default(() => selectedSurvey.deploying_for),
        language: yup.array().required("Please choose at least one language").default(() => selectedSurvey.supported_languages),
        surveyType: yup.string().required("Please choose the survey type").default(() => selectedSurvey.survey_type),
        downloadResponses: yup.boolean().required("Please choose whether you would like to download the collected responses").default(() => selectedSurvey.download_responses),
        employeeCount: yup.string().required("Please choose number of employees you are deploying your survey with").default(() => selectedSurvey.employee_count)
    });

    const {register: setup, handleSubmit, errors} = useForm({
        resolver: yupResolver(setupSchema)
    });

    const _handleNext = () => {
        handleSubmit(handleSetupNext)();
    };

    const _handleBack = () => {
        handleSetupBack();
    };

    return (
        <div className={"survey-setup"}>
            {
                showJoyride ? <Joyride steps={steps}
                                       scrollOffset={180}
                                       continuous={true}
                                       locale={{ last: 'Close' }}
                                       styles={{
                                           options:{
                                               primaryColor: '#6824ec'
                                           }
                                       }}
                                       callback={data => {
                                           const { status } = data;
                                           if (status === "skipped") {
                                               localStorage.setItem("joyride__setup--completed", "1");
                                           }
                                           if (status === "finished") {
                                               localStorage.setItem("joyride__setup--completed", "1");
                                           }
                                       }}
                                       disableScrollParentFix
                /> : null
            }

            <Progress selected={"setup"} errors={surveyErrors} handleReset={handleReset} handleNext={_handleNext} handleBack={_handleBack} isSaving={isSaving} selfServe={selfServe}/>
            <Container className={"survey-setup__content-container"}>
                <h5 className={"centered"}>Survey Setup</h5>
                <ol>
                    <li style={{ display: selfServeOrReturningUser && "none" }}>
                        <p className={"question-title"}>1. Are you deploying with your:</p>
                        <ul className={"survey-setup__questions-list"}>
                            <li>
                                <Form.Check
                                    label={"Employees"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"EMPL"}
                                    defaultChecked={!selfServeOrReturningUser && selectedSurvey.deploying_for === "EMPL"}
                                    id={"deployingWithEmployees"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Members"}
                                    type="radio"
                                    ref={setup}
                                    name={"deployingWith"}
                                    value={"MEM"}
                                    defaultChecked={selectedSurvey.deploying_for === "MEM"}
                                    id={"deployingWithMembers"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Portfolio Companies"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"PRT"}
                                    defaultChecked={selectedSurvey.deploying_for === "PRT"}
                                    id={"deployingWithPortfolioCompanies"} />
                            </li>
                            <li style={{ display: "none" }}>
                                <Form.Check
                                    label={"Returning Dashboard Users"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"CURR"}
                                    defaultChecked={returningUser}
                                    id={"deployingWithReturningDashboardUsers"} />
                            </li>
                            <li style={{ display: "none" }}>
                                <Form.Check
                                    label={"Self Serve"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"SELF"}
                                    defaultChecked={selfServe}
                                    id={"deployingSelfServe"} />
                            </li>
                            <li>
                                <Form.Control.Feedback
                                    type={"invalid"}
                                    className={classNames({ "d-block": errors.deployingWith })}>
                                    {errors.deployingWith && errors.deployingWith.message}
                                </Form.Control.Feedback>
                            </li>
                        </ul>
                    </li>
                    <li style={{ display: selfServeOrReturningUser && "none" }}>
                        <p className={"question-title"}>2. What language(s) are you looking to deploy your survey?</p>
                        <ul>
                            <li>
                                <Form.Check
                                    label={"English"}
                                    value={"en"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    defaultChecked={selectedSurvey.supported_languages && selectedSurvey.supported_languages.includes("en")}
                                    id={"english"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"French"}
                                    value={"fr"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    defaultChecked={selectedSurvey.supported_languages && selectedSurvey.supported_languages.includes("fr")}
                                    id={"french"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Spanish"}
                                    value={"esp"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    defaultChecked={selectedSurvey.supported_languages && selectedSurvey.supported_languages.includes("esp")}
                                    id={"spanish"} />
                            </li>
                            <li>
                                <Form.Control.Feedback
                                    type={"invalid"}
                                    className={classNames({ "d-block": errors.language })}>
                                    {errors.language && errors.language.message}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
                            </li>
                        </ul>
                    </li>
                    <li style={{ display: !selfServeOrReturningUser && "none" }}>
                        <p className={"question-title"}>1. Choose which survey you would like to create:</p>
                        <ul>
                            <li>
                                <Form.Check
                                    label={"Diversity survey only"}
                                    value={"D"}
                                    type="radio"
                                    ref={setup}
                                    name={"surveyType"}
                                    defaultChecked={selectedSurvey.survey_type === "D"}
                                    id={"D"} />
                                <Tooltip className="description-tooltip" title={"The Diversity survey only asks questions about gender, race and ethnicity, disability, and sexual orientation."}>
                                    <HelpIcon />
                                </Tooltip>
                            </li>
                            <li>
                                <Form.Check
                                    label={"Diversity and Inclusion survey"}
                                    value={"DNI"}
                                    type="radio"
                                    ref={setup}
                                    name={"surveyType"}
                                    defaultChecked={selectedSurvey.survey_type === "DNI"}
                                    id={"DNI"} />
                                <Tooltip className="description-tooltip" title={"Inclusion measures employee experiences across workplace dimensions along with the Diversity questions."}>
                                    <HelpIcon />
                                </Tooltip>
                            </li>
                        </ul>
                    </li>
                    <li style={{ display: "none" /*!selfServe && "none"*/ }}>
                        <p className={"question-title"}>2. Would you like to export the collected responses?</p>
                        <p><small>Please note that if you select yes, employees will need to be notified you will have access to raw data, which meaningfully impacts their willingness to self-identify and answer truthfully.</small></p>
                        <ul>
                            <li>
                                <Form.Check
                                    label={"Yes"}
                                    value={true}
                                    type="radio"
                                    ref={setup}
                                    name={"downloadResponses"}
                                    defaultChecked={selectedSurvey.download_responses === true}
                                    id={"yes"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"No"}
                                    value={false}
                                    type="radio"
                                    ref={setup}
                                    name={"downloadResponses"}
                                    defaultChecked={selectedSurvey.download_responses === false}
                                    id={"no"} />
                            </li>
                        </ul>
                    </li>
                    <li style={{ display: !selfServeOrReturningUser && "none" }}>
                        <p className={"question-title"}>2. Number of employees to be surveyed:</p>
                        <Form.Control
                            defaultValue={selectedSurvey.employee_count}
                            type="number"
                            ref={setup}
                            name={"employeeCount"}
                            min={0}
                            onChange={(e) => {
                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                            }}
                            id={"yes"} />
                    </li>
                </ol>
            </Container>
        </div>
    );
};

export default Setup;
