import React, {Component} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {urlMap} from "../dashboardRoutes";
import history from "../history";
import { ViewTracking } from 'hoc/ViewTracking';
import { VersionsContext } from 'context/versions/versionsContext/VersionsContext';

class PrivateRoute extends Component {
    static contextType = VersionsContext

    shouldComponentUpdate(nextProps, nextState, nextContext) {        
        if (this.context?.version !== nextContext?.version) {
            return true;
        }

        return (
            !nextProps.isAuthenticated
            || nextProps.location.pathname !== this.props.location.pathname
        );
    }

    render() {

        let { isAuthenticated, isLoading, isActive = true, component: Comp, permissions, viewTrackingName, ...rest } = this.props;

        if (!isActive || !permissions) {
             history.push('/home');
        }

        let navigateTo = Object.keys(urlMap).filter(urlKey => permissions[urlKey])[0];

        navigateTo = rest.path || navigateTo || "/home";

        if (!isActive || !permissions || navigateTo === "/") {
            navigateTo = "/home"
        }
        

        return (
            <Route exact {...rest} render={(props) => {
                const getComponent = () => {         
                    const component = <Comp {...rest} {...props} permissions={permissions} />

                    if (viewTrackingName) {
                        return (
                            <ViewTracking viewTrackingName={viewTrackingName}>
                                {component}
                            </ViewTracking>
                        )
                    }

                    return component
                }
 
                return (
                    isAuthenticated
                        ? getComponent()
                        : <Redirect
                            to={{
                                pathname: sessionStorage.getItem("companyUUID") ? `/login?to=${encodeURIComponent(location.pathname + location.search)}` : '/login',
                                state: {
                                    prevLocation: navigateTo,
                                    error: "You need to login first!",
                                },
                            }} />
                )
            }} />
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    }
};

export default connect(mapStateToProps)(PrivateRoute);
