import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadProfile, updateProfile } from './network';
import { stringifyQueryParams } from '../utils';
import * as Sentry from "@sentry/react";

export default [
    [
        ActionTypes.GET_PROFILE
        , getProfile
    ],
    [
        ActionTypes.DO_UPDATE_PROFILE
        , doUpdateProfile
    ]
];

function * getProfile () {
    yield put(Actions.loadingProfile());

    const response = yield call(loadProfile);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        Sentry.setContext("profile", response.data)
        yield put(Actions.loadedProfile(response.data));
    }
}

function * doUpdateProfile ({payload}) {
    yield put(Actions.doingUpdateProfile());

    const response = yield call(updateProfile, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: "Fields may not be left empty"}));        


        yield put(
            Actions.showToast({
              type: "warning",
              message: "Something went wrong",
            })
        );
    } else {
        yield put(Actions.doneUpdateProfile(response.data));

        yield put(
            Actions.showToast({
              type: "success",
              message: "Successfully updated profile.",
            })
        );
    }
}
