import React, { useState, useEffect } from 'react';
import Progress from "./Progress";
import { withTranslation } from 'react-i18next';
import {Button, Row, Col, Nav, Accordion} from "react-bootstrap";
import {Card} from "react-bootstrap";
import classNames from "classnames";
import {copyToClipboard} from "../../utils";
import moment from "moment";
import Create from "./Create";
import refresh from '../../assets/images/refresh.svg';
import Joyride from "react-joyride";
import Loader from "../utils/Loader";
import SurveyTemplates from "./SurveyTemplates";

const Live = (props) => {
    const { savedSurveys, handleClose, surveyErrors, isLoadingRefreshCount, doRefreshResponseCount, selfServe, t } = props;
    const [showJoyride, setShowJoyride] = React.useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (localStorage.getItem("joyride__live--completed") !== "1") {
                setShowJoyride(true);
            }
        }, 2000);

        return () => {
            setShowJoyride(false);
        }
    }, [])

    const _rotateToggle = (e, accordion) => {
        const hasClass = e.target.classList.contains("open");
        document.querySelectorAll('.survey-create .collapse').forEach(el => el.parentNode.querySelector(".card-header").classList.remove("open"));
        e.target.classList[hasClass ? "remove" : "add"]("open");
    };

    const [steps, setSteps] = React.useState([
        {
            target: '.copy-link-container',
            content: 'Now that you have finalized your survey, get access to your shareable link here.',
            disableBeacon: true,
        },
        {
            target: '.collector-link',
            content: 'Copy this link and share it with your colleagues.',
        },
        {
            target: '.refresh-icon',
            content: 'Once your colleagues complete their survey, refresh here to get an updated number of completed surveys. Note that this may have a 10-15 minute delay.',
            placement: "left"
        },
        {
            target: '.close-survey',
            content: 'When you’re ready, end your survey and create your dashboard!',
        },
    ])
    const lastSavedSurvey = savedSurveys[0];
    const [language, setLanguage] = useState("en");
    if (!lastSavedSurvey) { return null; }
    const { surveys } = lastSavedSurvey;
    const percentComplete = lastSavedSurvey.total_responses/lastSavedSurvey.employee_count * 100;
    const selectedSurvey = surveys.filter(survey => survey.supported_language === language)[0]

    return (
        <div className={"survey-live"}>
            <Progress selected={"live"} errors={surveyErrors} selfServe={selfServe}/>
            {
                showJoyride ? <Joyride steps={steps}
                                       scrollOffset={300}
                                       continuous={true}
                                       locale={{ last: 'Close' }}
                                       styles={{
                                           options:{
                                               primaryColor: '#6824ec'
                                           }
                                       }}
                                       callback={data => {
                                           const { status } = data;
                                           if (status === "skipped") {
                                               localStorage.setItem("joyride__live--completed", "1");
                                           }
                                           if (status === "finished") {
                                               localStorage.setItem("joyride__live--completed", "1");
                                           }
                                       }}
                                       disableScrollParentFix
                /> : null
            }

            <div id={"survey-live-content"}>
                <Row>
                    <Col>
                        <Card>
                            <Row>
                                <Col xs={12}>
                                    <h5>Your Survey is Live!</h5>
                                    <p>For maximum engagement from employees, we recommend sending out reminders to complete the survey every 4 days. The average duration for a survey is 3 weeks.</p>
                                    <p>Send your employees your link to get started. {!selfServe && "If you created survey in multiple languages, choose the language you want to send to your employees."}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className={'card-fh'}>
                    <Col xs={8}>
                        <Card className='card-fh'>
                            <Row className={'card-fh'}>
                                <Col xs={12} className={"flex flex-column card-fh"}>
                                    {
                                        surveys.length > 1 && <Nav variant="tabs" defaultActiveKey={language}>
                                            {
                                                surveys.map(survey => {
                                                    return <Nav.Item>
                                                        <Nav.Link onClick={() => setLanguage(survey.supported_language)} eventKey={ survey.supported_language }>{t(`SURVEY.${survey.supported_language.toUpperCase()}`)}</Nav.Link>
                                                    </Nav.Item>
                                                })
                                            }
                                        </Nav>
                                    }
                                    <small className={"mb-2 text-center"}>Get started by sending this link to your employees</small>
                                    {
                                        surveys.map(survey => {
                                            return <div className={classNames("copy-link-container", {"hidden": survey.supported_language !== language})}>
                                                <input className={"collector-link"} type="text" value={survey.collector_link}/>
                                                <div className={"copy"} onClick={e => {
                                                    const target = e.target;
                                                    target.firstChild.innerText = "Copied";
                                                    setTimeout(() => {
                                                        target.firstChild.innerText = "Copy";
                                                    }, 5000);
                                                }}>
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div className={'close-survey-container'}>
                                        <h6>Ready to close your survey?</h6>
                                        <Button variant={"primary"} className={"close-survey"} onClick={handleClose}>
                                            {
                                                props.isLoading
                                                    ? <Loader />
                                                    : "Close Survey"
                                            }
                                        </Button>
                                        <p className={'closing-note'}><small>When you are ready to close your survey and generate results please let us know by clicking the "Close Survey" button.</small></p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={4}>
                        <Card className={"submitted-response-card card-fh"}>
                            <h5>Submitted Responses</h5>
                            <p style={{ visibility: !lastSavedSurvey.last_response_checked ? "hidden" : "visible" }}><small>Updated {moment(lastSavedSurvey.last_response_checked).format("lll")}</small></p>
                            <div className={"responses-highlight"}>
                                <div className={classNames('progress-arc', `p-${Math.round(percentComplete/10) * 10}`)}></div>
                                <div className='total_responses_container'>
                                    {lastSavedSurvey.total_responses}
                                    <span className={"employee-count"}> / {lastSavedSurvey.employee_count}</span>
                                </div>
                            </div>
                            {
                                lastSavedSurvey.employee_count ? <span className={'percent-complete'}>{percentComplete?.toFixed(2)}% completed</span> : null
                            }
                            <div className={"refresh-container"}>
                                <img src={refresh}
                                     className={classNames({"is-loading": isLoadingRefreshCount}, "refresh-icon")}
                                     alt="Refresh"
                                     onClick={() => {
                                         doRefreshResponseCount({ surveyinfo_id: lastSavedSurvey.id })
                                     }}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Accordion defaultActiveKey="-1" onClick={(e) => _rotateToggle(e, "Communication Templates")}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            { t("SURVEY.SURVEY_TEMPLATES") }
                            <div className="toggle-container">
                                <small className="toggle-view">View</small>
                                <small className="toggle-hide">Hide</small>
                                <img className={"icon icon-caret"}/>
                            </div>

                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className={"intro-content"}>
                                <SurveyTemplates surveyLink={selectedSurvey.collector_link}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="-1" onClick={(e) => _rotateToggle(e, "Your Survey")}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            { t("SURVEY.YOUR_SURVEY_HEADING") }
                            <div className={"toggle-container"}>
                                <small className="toggle-view">View</small>
                                <small className="toggle-hide">Hide</small>
                                <img className={"icon icon-caret"}/>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className={"intro-content"}>
                                <Create { ...props } preview/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    );
};

export default withTranslation()(Live);
