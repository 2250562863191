import { getCompanyFilters } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import {
    GetCompanyFiltersResponse,
    GetCompanyFiltersTransformedResponse,
} from 'api/actions/analyze/companyFilters/companyFiltersActions.types'
import { Queries } from 'api/queries.enum'
import { Demographics } from 'components/AnalyzeV2/Diversity/diversity.types'
import { CompanyProfilesParams } from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { useQuery } from 'hooks/useQuery'

export const useGetCompanyFilters = (params?: CompanyProfilesParams, isAllowedPath?: boolean) => {
    const transformData = (data: GetCompanyFiltersResponse): GetCompanyFiltersTransformedResponse => {
        return {
            ...data,
            filtersModal: data.filtersModal.map((filter) => ({
                ...filter,
                key: filter.name,
                name: filter.label,
            })),
            filtersPane: {
                ...data.filtersPane,
                demographics: data.filtersPane.demographics.map((filter) => ({
                    ...filter,
                    key: filter.type === Demographics.Bespoke ? filter.uuid : filter.name,
                    name: filter.type === Demographics.Bespoke ? filter.name : filter.label,
                })),
                inclusion: data.filtersPane.inclusion.map((filter) => ({
                    ...filter,
                    key: filter.type === Demographics.Bespoke ? filter.uuid : filter.type,
                    name: filter.name,
                })),
            },
        }
    }

    const queryKey = Queries.getCompanyFilters
    const queryFn = getCompanyFilters

    const query = useQuery<GetCompanyFiltersTransformedResponse, Error>({
        queryKey: [queryKey, params],
        queryFn: () => {
            return queryFn(stringifyQueryParams(params))
        },
        select: transformData,
        keepPreviousData: true,
        notifyOnChangeProps: ['data', 'isFetched', 'isLoading'],
        enabled: isAllowedPath,
    })

    return query
}
