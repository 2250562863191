export enum AppRoute {
    AccountMfa = '/account/mfa',
    Analyze = '/analyze2',
    AnalyzeDiversity = '/analyze2/diversity',
    AnalyzeInclusion = '/analyze2/inclusion',
    ChooseDashboard = '/choose-dashboard',
    Default = '/',
    Error = '/error',
    ForgotPassword = '/forgot-password',
    Home = '/home2',
    HomeV1 = '/home',
    IlnDashboardHome = '/iln-dashboard/home',
    Login = '/login',
    Programs = '/programs',
    Recommended = '/recommended',
    Register = '/register',
    ResetPassword = '/reset-password/:token',
    ScheduledMaintenance = '/scheduled-maintenance',
    SelfServeRegister = '/self-serve-register',
    SendVerificationCode = '/send-verification-code',
    SolutionGoals = '/goals',
    Sso = '/sso',
    SsoCallback = '/sso/callback',
    Survey = '/survey',
    Tools = '/tools',
    TwoStepVerification = '/two-step-verification',
    TwoStepVerificationBackup = '/two-step-verification-backup',
}
