import { diversioApiV2 } from 'api/axios/axiosInstance'
import { getCompanyUUID } from 'utils/getCompanyUUID/getCompanyUUID'

import { endpoints } from './endpoints'
import { GetInclusionScoresSkeletonParams, GetInclusionScoresSkeletonResponse } from './inclusionDataActions.types'

export const getInclusionScoresSkeleton = async (
    params: GetInclusionScoresSkeletonParams,
): Promise<GetInclusionScoresSkeletonResponse> => {
    const uuid = getCompanyUUID() || ''

    const response = await diversioApiV2.get(endpoints.inclusionScoresSkeleton(uuid), { params })

    return response.data
}
