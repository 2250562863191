import { diversioApi } from 'api/axios/axiosInstance'

import { GetConfigurationResponse } from './configurationActions.types'
import { endpoints } from './../../endpoints'

export const getConfiguration = async (): Promise<GetConfigurationResponse> => {
    const response = await diversioApi.get(endpoints.configuration)

    return response.data
}
